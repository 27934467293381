const BlogPosts = [
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-47-cover.jpg',
    date: '08 October 2024',
    link: '/blog/2024-10-08/new-standby-insights-save-more-on-your-energy-bill',
    title: 'New Standby Insights: Save More on Your Energy Bill',
    preview:
      "We are excited to announce the launch of a new feature in the Voltaware app—Standby Insights. This tool gives you a clear understanding of how efficiently you are managing the energy consumption of your standby appliances.",
    text: [
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-47.jpg" alt="New Standby Insights: Save More on Your Energy Bill" /></div>',
      '<h3>Introducing Standby Insights: Helping You Save More on Your Energy Bill</h3>',
      '<p class="justified">We are excited to announce the launch of a new feature in the Voltaware app—Standby Insights. This tool gives you a clear understanding of how efficiently you are managing the energy consumption of your standby appliances.</p>',
      '<h3>What are Standby Appliances?</h3>',
      '<p class="justified">Standby appliances include devices such as computer monitors, Wi-Fi routers, entertainment units, speakers, TVs, chargers, and more. These devices draw a small but constant amount of energy, even when they’re turned off or not in active use. While the amount of energy consumed may seem insignificant on an individual basis, the cumulative effect can result in a surprisingly large proportion of your energy bill. Reducing this "phantom" consumption can make a big impact on your savings.</p>',
      '<h3>Standby Insights: Your Energy Efficiency Companion</h3>',
      '<p class="justified">The new <strong>Standby Insights</strong> feature provides an easy-to-understand monthly <strong>Standby Overall Score</strong> to help you track your standby energy consumption. With this score, you can see how efficiently you\'re using standby appliances and identify areas where you can improve.</p>',
      '<p class="justified">Here’s what Standby Insights will show you:</p>',
      `<div class="xs-screen-wrap" style="display: flex; justify-content: center; align-items: center;">
        <ul class="with-margin-list">
          <li>
            <strong>Historical Standby Score:</strong> Each month, we compare your current standby consumption to your historical average, showing whether your usage has increased or decreased over time.
          </li>
          <li>
            <strong>Similar Home Standby Score:</strong> We provide a comparison of your standby consumption to similar homes, helping you see how your energy use measures up.
          </li>
          <li>
            <strong>Annual Standby Cost:</strong> Want to know how much your standby power is costing you annually? We calculate this using your provided tariff information. If you've been using our sensor for over a year, we use your actual standby consumption data. Otherwise, we estimate it based on your average monthly usage.
          </li>
          <li>
            <strong>Potential Savings:</strong> Discover how much you could save by reducing your standby consumption to the optimal level for a home like yours. We calculate this by comparing your projected annual standby cost with the ideal cost based on your consumption profile.
          </li>
        </ul>
      </div>`,
      '<h3>How to Reduce Your Standby Consumption</h3>',
      '<p class="justified">Now that you have the insights, here are some simple tips to reduce your standby energy use and save on your energy bills:</p>',
      `<div class="xs-screen-wrap" style="display: flex; justify-content: center; align-items: center;">
        <ol class="with-margin-list">
          <li>
            <strong>Unplug Devices Not in Use:</strong> Chargers, entertainment systems, and kitchen appliances that aren’t in active use can often be unplugged. Power strips with on/off switches can make this easier to manage.
          </li>
          <li>
            <strong>Use Smart Plugs:</strong> Smart plugs allow you to control when certain devices are powered, giving you the option to cut off energy supply remotely or on a schedule.
          </li>
          <li>
            <strong>Turn Off Computers and Monitors:</strong> Computers and their peripherals (monitors, printers, etc.) can draw significant standby power. When not in use, turn them off completely or use power-saving settings.
          </li>
          <li>
            <strong>Set Timers for Your Wi-Fi Router and TV:</strong> If you don’t need your Wi-Fi overnight or during certain hours, setting timers can reduce energy waste. The same applies to TVs and other entertainment units.
          </li>
          <li>
            <strong>Enable Power-Saving Features:</strong> Many modern devices come with power-saving modes that reduce the amount of standby power they consume. Check your devices to see if this feature is available.
          </li>
        </ol>
      </div>`,
      '<h3>What\'s Next?</h3>',
      '<p class="justified">The Standby Insights feature is just the beginning. Over time, we will expand our insights to include more and more appliances.</p>',
      '<p class="justified">Stay tuned for more updates as we continue to make energy saving easier and more accessible for everyone.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-46-cover.jpg',
    date: '24 September 2024',
    link: '/blog/2024-09-24/meet-the-team-guzel-oktyabreva',
    title: 'Meet the team | Guzel Oktyabreva da Silva',
    preview:
      "Welcome to another edition of our 'Meet the Team' interview series! This time, we’re excited to shine the spotlight on Guzel, a key contributor in the Voltaware family. With nearly 20 years of sales experience under her belt, Guzel is instrumental in driving growth and building meaningful connections with our clients. Her passion for innovation and commitment to sustainability make her a perfect fit for our mission to reshape the energy landscape.",
    text: [
      "<p class='justified'>Meet Guzel, our dedicated Business Development Representative, who brings a wealth of experience and a vibrant personality to the team. Living in Brazil, she is not only a fan of the Flamengo football team but also enjoys the beauty of both beaches and mountains. As an Aquarius, she believes her sign is the best, embodying the traits of creativity and independence. When she's not engaging with clients or strategizing with her sales team, you can find her enjoying the great outdoors or cheering on her favorite football club. Get ready to learn more about Guzel's inspiring journey, her insights into sales, and the joyful spirit she brings to Voltaware!</p>",
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-46.jpg" alt="Meet the team | Guzel Oktyabreva da Silva" /></div>',
      '<h3>What motivated you to pursue a career in business development, and how has this journey aligned with your long-term professional goals?</h3>',
      '<p class="justified">I’m Guzel, a Business Development Representative at Voltaware, where I’ve been for 10 months. I’ve always been motivated by the challenge of building connections and helping companies grow. Business development allows me to combine strategy and communication, which I enjoy. It’s aligned with my long-term goals of working in dynamic industries like energy, where I can make a real impact. Plus, the constant learning keeps me motivated and growing.</p>',
      '<h3>Which aspects of Voltaware’s mission resonate most with you, and how do you see yourself contributing to these goals in your role as a Business Development Representative?</h3>',
      '<p class="justified">Voltaware’s focus on energy management and sustainability really resonates with me because I believe in using technology to create a greener future. I’m particularly excited about how we help utilities boost customer engagement through AI-powered energy insights, making energy use more transparent and efficient for households. As a BDR, I contribute by helping clients see the value of our solutions in reducing energy waste and improving efficiency. It’s rewarding to be part of a mission that’s both innovative and meaningful.</p>',
      '<h3>One of your main responsibilities is lead generation and prospecting for new opportunities. What’s one essential trait for success in this role?</h3>',
      '<p class="justified">One of the most crucial traits for success in lead generation is the courage to approach individuals at all levels within a prospective company. It’s important to be confident that decision-makers will respond if you can effectively demonstrate the true value that Voltaware can add to their strategy. This fearless approach helps me reach the highest C-level prospects and initiate valuable conversations. By showcasing our innovative solutions that enhance customer engagement and foster strong relationships, you can create a compelling case for collaboration.</p>',
      '<h3>Artificial Intelligence (AI) is becoming increasingly important in data analytics. How do you see AI transforming the energy sector, and what role does it play in Voltaware’s work with energy data?</h3>',
      '<p class="justified">AI technology looks set to revolutionise the energy sector: smart homes are becoming smarter; consumers are increasingly using AI to make intelligent energy choices; and we are even seeing the use of deep learning based real-time models for demand side management. Voltaware is at the cutting edge of this AI revolution within the energy sector. As mentioned above, we leverage the power of AI/ML to accurately detect appliance signatures, and provide personalised insights which help customers reduce their energy costs as well as their carbon footprint.</p>',
      '<h3>Your LinkedIn profile mentions that you’re an advocate for remote work. How do you maintain high levels of motivation and productivity while working remotely, and which tools or practices do you rely on to manage your workflow efficiently?</h3>',
      '<p class="justified">I maintain motivation and productivity by setting clear goals and embracing a structured routine. Each day kicks off with a workout at the gym, which greatly contributes to my physical and mental well-being. After several years of remote work, I’ve established a dedicated home office that supports my productivity. I rely on tools like Pipedrive to track leads efficiently, Slack for seamless communication with my team, and Trello to manage my tasks and stay organized. These tools help me visualize my workflow and prioritize effectively. During breaks, I enjoy watering my plants and chatting with my two canaries, which allows me to recharge and stay refreshed throughout the day.</p>',
      '<h3>What’s one unique or unconventional insight you’ve gained from collaborating with people from different cultures or industries, and how has it impacted your approach to business development?</h3>',
      '<p class="justified">One key insight I’ve gained from working with people from various cultures and industries is the importance of flexibility and open-mindedness. Everyone has a unique way of approaching problems, and being receptive to different perspectives can inspire more innovative solutions. Having visited over 50 countries has provided me with invaluable experiences, allowing me to meet a wide range of nationalities. Each interaction has taught me something new, enriching my understanding and broadening my mindset, which ultimately enhances my approach to business development.</p>',
      '<p class="justified">I’m also grateful that at Voltaware, we have an international team with co-workers from India, Brazil, France, Spain, the UK, Italy, and many others, allowing me to continue learning from their diverse perspectives.</p>',
      '<h3>Who has been the most influential person in your life, and how have they shaped your mindset and approach to overcoming both personal and professional challenges?</h3>',
      '<p class="justified">The most influential person in my life has been my mother. She instilled in me the values of persistence and resilience, teaching me that challenges are simply opportunities for growth. For instance, during my first year at university, I was told I had no talent for learning languages and should consider switching majors. My mother encouraged me to stay the course and put in my best effort. Thanks to her unwavering support, I persevered and now speak four languages, a testament to the power of determination and belief in oneself. Her influence has shaped my mindset, guiding me through both personal and professional challenges with confidence and tenacity.</p>',
      '<h3>A quick sprint round of “this or that” questions</h3>',
      `<ol style="margin-left: 2rem;">
        <li>Networking events or online webinars?</li>
        <ul><li>I love networking events and professional fairs.</li></ul>
        <li>Email outreach or social media engagement?</li>
        <ul><li>Social media engagement</li></ul>
        <li>Data-driven decisions or intuition-based decisions?</li>
        <ul><li>Data-driven decisions</li></ul>
        <li>Energy efficiency or renewable energy?</li>
        <ul><li>Energy efficiency</li></ul>
        <li>Morning meetings or afternoon meetings?</li>
        <ul><li>Morning meetings, definitely!</li></ul>
        <li>Work hard or work smart?</li>
        <ul><li>I believe in working both hard and smart</li></ul>
        <li>The best place to live in?</li>
        <ul><li>Rio de Janeiro, Brazil! 🇧🇷</li></ul>
      </ol>`,
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-45.jpg',
    date: '07 August 2024',
    link: '/blog/2024-08-07/meet-the-team-furqan-tahir',
    title: 'Meet the team | Furqan Tahir',
    preview:
      "Welcome to another edition of our 'Meet the Team' interview series! This time, we're thrilled to introduce you to a key player in the Voltaware family. While our cutting-edge technology often grabs the headlines, it's the dedicated and talented individuals behind the scenes who are the real heroes of our success. In this edition, we shine a spotlight on Furqan Tahir, our Head of Smart Meter Insights, who is at the forefront of transforming energy data into meaningful insights.",
    text: [
      "<p class='justified'>Meet Furqan Tahir, the mastermind behind Voltaware’s innovative Smart Meter Insights. As our Head of Smart Meter Insights, Furqan blends the precision of data science with the art of AI/ML, creating a symphony of energy disaggregation that turns your home’s energy data into actionable insights. When he’s not fine-tuning AI models or synchronizing with his team across time zones, Furqan can be found hiking up a trail, perfecting his tennis serve, or cycling through scenic routes. Get ready to dive into the fascinating world of smart meters, AI, and a dash of lasagna love, as we uncover what makes Furqan tick both inside and outside the office.</p>",
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-45.jpg" alt="Meet the team | Furqan Tahir" /></div>',
      '<h3>Could you describe your role as the Head of Smart Meter Insights at Voltaware? What does your typical day look like?</h3>',
      '<p class="justified">As the Head of Smart Meter Insights, I lead a team of Data Scientists, Analysts and Backend Engineers to develop AI/ML based solutions for energy disaggregation and personalised actionable insights using only the home’s smart meter consumption data.</p>',
      '<p class="justified">My typical day starts with a review of the product’s Jira board. This involves creating and updating various epics/cards for the team to work on and ensure that the product’s development activities remain aligned with the company’s business priorities. I would then have the daily standup meeting with the Data Science Team, where each member provides a quick overview of what they are currently working on, whether they face any blockers or require help with any particular task.</p>',
      '<p class="justified">I usually dedicate some morning time to have in-depth technical discussions with relevant Team members on implementing new product features as well as enhancing/maintaining the library. In addition to this, I also make it a point to set aside at least a couple of hours each day for core library development work. This typically involves tasks related to training and testing AI/ML disaggregation models for new appliances as well as refining some of the existing appliance models etc.</p>',
      '<p class="justified">In the afternoon, I have a catchup with the Backend engineers, who are mostly based in Brazil, to align on the technical requirements for any new product features, deployment activities as well as exposing new insights on the API for our customers.</p>',
      '<p class="justified">To cap off the day, there are often calls with new/existing customers to update them on the project activities, discuss disaggregation results and insights as well as provide updates on the product roadmap features.</p>',
      '<h3>You’ve been with Voltaware for over three years. What has been your most rewarding project involving energy data insights, and what impact did it have on the company’s goals?</h3>',
      '<p class="justified">I started working on the Smart Meter disaggregation project a couple of years ago. This involved developing a library that leverages the power of AI/ML to accurately detect appliance signatures within the smart meter energy data. Whilst this project has been challenging due to the low resolution of the smart meter data, however, we now have a product that can accurately estimate the consumption of most major appliances found within homes. Furthermore, we have developed an AI/ML based module to infer each home’s appliance list (thereby eliminating the need to have this information availablefrom the customer). This means that we only require the home’s smart meter data to generate an accurate bill breakdown each week and provide personalised actionable insights to the customers. This has enabled Voltaware to offer energy disaggregation and personalised insights to a wide spectrum of clients.</p>',
      '<h3>How do you balance the need for detailed energy usage insights with privacy concerns for end-users? What measures does Voltaware implement to ensure data security and privacy?</h3>',
      '<p class="justified">We have purposefully designed the Smart Meter Product in a way that it only requires the home’s load curve and not much else. For example, we often find that customer home’s metadata (appliance list), whilst very useful, is often hard to obtain. Therefore, as mentioned above, we rely only on the smart meter energy data to infer the homes appliance list and provide energy disaggregation and personalised insights. Any data that we receive from our clients is anonymised and we fully comply with the GDPR regulations to protect the privacy and personal data of individuals</p>',
      '<h3>Artificial Intelligence (AI) is becoming increasingly important in data analytics. How do you see AI transforming the energy sector, and what role does it play in Voltaware’s work with energy data?</h3>',
      '<p class="justified">AI technology looks set to revolutionise the energy sector: smart homes are becoming smarter; consumers are increasingly using AI to make intelligent energy choices; and we are even seeing the use of deep learning based real-time models for demand side management. Voltaware is at the cutting edge of this AI revolution within the energy sector. As mentioned above, we leverage the power of AI/ML to accurately detect appliance signatures, and provide personalised insights which help customers reduce their energy costs as well as their carbon footprint.</p>',
      '<h3>How do you stay updated with the latest advancements in data science?</h3>',
      '<p class="justified">Data Science is rapidly advancing and it is important to stay up-to-date with the latest developments. To this end, I attend webinars/talks from leading experts in the field as well as enroll in relevant online courses (where possible).</p>',
      '<h3>Outside of work, what are some of your hobbies or interests?</h3>',
      '<p class="justified">I enjoy playing tennis, hiking and cycling.</p>',
      '<h3>Last one is a “lightning round”</h3>',
      `<ol style="margin-left: 2rem;">
        <li>Favourite color</li>
        <ul><li>Blue</li></ul>
        <li>Favourite food</li>
        <ul><li>Lasagna</li></ul>
        <li>Favourite season</li>
        <ul><li>Spring</li></ul>
        <li>Tea or coffee?</li>
        <ul><li>Tea</li></ul>
        <li>Python or Java?</li>
        <ul><li>Python</li></ul>
        <li>Introvert or extrovert?</li>
        <ul><li>Extrovert</li></ul>
        <li>Gym or sofa?</li>
        <ul><li>Gym</li></ul>
        <li>The Big Bang Theory or Friends?</li>
        <ul><li>The Big Bang Theory</li></ul>
      </ol>`,
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-44-cover.jpg',
    date: '26 July 2024',
    link: '/blog/2024-07-26/voltawares-new-app-long-term-customer-engagement',
    title: "Voltaware's New App: Long-Term Customer Engagement",
    preview:
      "We are thrilled to unveil what we've been meticulously crafting over the past few months: the new Voltaware Home app.",
      text: [
        '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-44.jpg" alt="Voltaware\'s New App: Long-Term Customer Engagement" /></div>',
        '<p class="justified">At Voltaware, we are thrilled to unveil what we\'ve been meticulously crafting over the past few months: the new Voltaware Home app. This groundbreaking application is designed to enhance the user experience, offering deeper insights and simplifying implementation for utilities, making it a win-win for both customers and service providers.</p>',

        '<h3>Help Your Customers Finally Take Command of Their Energy Use</h3>',
        '<p class="justified">Empower your customers with actionable insights and real-time data. The new Voltaware Home app includes:</p>',
        `<div class="xs-screen-wrap" style="display: flex; justify-content: center; align-items: center;">
          <ul class="with-margin-list items-padded-40" style="margin-left: 0; margin-right: 35px;">
            <li><b>Voltascore:</b> A comprehensive score reflecting
        overall energy efficiency, helping users understand
        their consumption patterns at a glance.</li>
            <li><b>Real-time Disaggregation:</b> See which appliances
        are being used at any moment, giving users the
        power to make immediate adjustments.</li>
            <li><b>Personalized Recommendations:</b> Tailored
        suggestions based on individual usage patterns to
        help users optimize their energy consumption and
        achieve greater efficiency.</li>
            <li><b>Weekly Disaggregation:</b> Detailed weekly
        breakdowns of energy consumption, enabling users
        to track their usage trends over time.</li>
            <li><b>New Monthly Reports:</b> Insightful monthly reports
        that track and compare energy usage trends,
        helping users make informed decisions.</li>
          </ul>
          <div style="display: flex; flex-direction: column; align-items: center">
            <img src="/img/new-app/voltascore-screen.png" class="lg-max-width-360" alt="Voltascore screen" />
            <p class="figure-caption" style="margin: 0; font-style: italic">Voltascore provides a quick overview of energy efficiency.</p>
          </div>
        </div>`,
        `<div class="xs-screen-wrap" style="display: flex; justify-content: center; align-items: center;">
          <div class="xs-screen-order-2" style="display: flex; flex-direction: column; align-items: center">
            <img src="/img/new-app/breakdown-screen.png" class="lg-max-width-360" alt="Breakdown screen" />
            <p class="figure-caption" style="margin: 0; font-style: italic">Real-time Disaggregation shows active appliances and their energy consumption.</p>
          </div>
          <div>
            <p class="justified">
              With these features, users can finally take control of their energy usage, identifying opportunities for savings and reducing their environmental footprint. And this is just the beginning—there's a lot more coming in the next few weeks, so keep an eye out for these exciting updates!
            </p>
          </div>
          
        </div>`,

        '<h3>Smoother Customer Experience and Increased Engagement</h3>',
        '<p class="justified">We’ve reimagined the entire user journey to make interactions seamless and intuitive. The new app interface is designed with the user in mind, ensuring every touchpoint is straightforward and engaging. Here’s a sneak peek of the new design:</p>',
        `<div class="xs-screen-wrap" style="display: flex; justify-content: center; align-items: center;">
          <div style="margin-right: 35px">
            <ul class="with-margin-list items-padded-40" style="margin-left: 0">
              <li><b>User-Centric Design:</b> Every element of the app has been designed with the end-user in mind, ensuring an intuitive and enjoyable experience.</li>
              <li><b>Enhanced Navigation:</b> Simplified menus and clear, concise labels make it easy for users to find what they need.</li>
              <li><b>Engaging Visuals:</b> Interactive charts and graphs provide a visually appealing way to track energy usage.</li>
            </ul>
            <p class="justified">
              With these improvements, customers will find it easier than ever to navigate the app and stay engaged with their energy usage. The redesigned interface ensures that important information is always at their fingertips, fostering a deeper connection with their energy consumption patterns.
            </p>
          </div>
          <div style="display: flex; flex-direction: column; align-items: center">
            <img src="/img/new-app/home-screen.png" class="lg-max-width-360" alt="Home screen" />
            <p class="figure-caption" style="margin: 0; font-style: italic">The new Home Screen, featuring a clean and intuitive layout.</p>
          </div>
        </div>`,
        
        '<h3>Seamlessly Deploy White-Label App Across Your Customer Base</h3>',
        '<p class="justified">The new Voltaware Home app offers unmatched flexibility for utilities. Our solution can be seamlessly white-labeled, allowing you to maintain your brand identity while providing enhanced functionalities to your customers.</p>',
        `<div class="xs-screen-wrap" style="display: flex; justify-content: center; align-items: center;">
            <ul class="with-margin-list items-padded-40" style="margin-left: 0">
              <li><b>Flexibility to Implement:</b> Easily add new features and changes to your white-labeled app, ensuring it evolves with your needs.</li>
              <li><b>Component Integration:</b> Implement all or selected components of the new app into your existing application, providing a tailored experience for your users.</li>
              <li><b>Account Linking:</b> Link the new application account with your current application for a unified user experience, ensuring a smooth transition and consistent branding.</li>
            </ul>
          </div>`,
        `<div style="display: flex; flex-direction: column; align-items: center">
          <img src="/img/new-app/white-labeled-app.png" class="lg-max-width-360" alt="White-labeled app" />
          <p class="figure-caption" style="margin: 0; font-style: italic">Example of a white-labeled customization, maintaining brand identity.</p>
        </div>`,
        '<p class="justified">This flexibility ensures that utilities can quickly adapt to changing market demands and customer expectations, providing a cutting-edge service without the need for extensive redevelopment.</p>',
        
        '<h3>Conclusion</h3>',
        '<p class="justified">The new Voltaware Home app represents a significant step forward in customer engagement and energy management. We are excited to support utilities in delivering an unparalleled user experience. By offering advanced features and seamless integration options, we empower utilities to help their customers take control of their energy use and pave the way for a more sustainable future.</p>',
        '<p class="justified">Moreover, Voltaware\'s solution/product opens up new revenue opportunities through targeted upselling and personalized energy-saving recommendations. Utilities can leverage it to offer premium features, maintenance services, and energy-efficient product promotions, driving additional revenue while enhancing customer satisfaction.</p>',
        '<p class="justified">For more information and resources, visit our website or contact us directly. Together, we can help your customers take control of their energy use and pave the way for a more sustainable future.</p>',
      ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-43.jpg',
    date: '12 June 2024',
    link: '/blog/2024-06-12/meet-the-team-emil-aliev',
    title: 'Meet the team | Emil Aliev',
    preview:
      "Welcome to another edition of our 'Meet the Team' interview series! In this edition, we're excited to shine a spotlight on another remarkable member of the Voltaware family. While our innovative technology often takes centre stage, it's the talented individuals behind the scenes who truly drive our success.",
    text: [
      '<p class="justified">Please meet Emil Aliev - our dynamic and dedicated Business Development Executive, who joined Voltaware in 2023. With his impressive expertise in business development and sales, Emil plays a pivotal role in driving our success and fostering strong client relationships.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-43.jpg" alt="Meet the team | Emil Aliev" /></div>',
      '<h3>Can you share a bit about your role at Voltaware and what a typical day looks like for you?</h3>',
      '<p class="justified">I joined Voltaware\'s Business Development team in 2023 following the completion of my Master\'s degree. My main responsibilities include lead generation, marketing, account management, and customer relations. No two days are the same for me. A typical day usually involves researching different geographies, business client profiles and markets and then identifying new B2B2C leads and potential partners based on the research.</p>',
      '<p class="justified">Furthermore, I constantly engage with existing clients and partners, manage the client onboarding process, ensure they have the best customer experience, and assess the progress of our commercial deployments. Additionally, I create marketing materials for our campaigns and exhibitions in line with our product teams.</p>',
      '<h3>What drew you to Voltaware initially, and what keeps you motivated to work here?</h3>',
      '<p class="justified">First of all, Voltaware\'s main mission is to help households reduce their energy waste, adopt sustainable behaviour changes and reduce their emissions. These aims are very important in light of the climate change topic, the current state of the energy market, and air pollution. I always wanted to be part of a company that has a positive impact on the world and people on a global scale. Secondly, I was drawn by the innovativeness f the products offered by Voltaware.</p>',
      '<p class="justified">When I first came across Voltaware, I couldn\'t even imagine that such products as AI-powered appliance-level energy insights existed. Furthermore, working in such an innovative start-up provides me with exposure to a wider range of experiences, fostering my growth into a more versatile professional compared to working in a traditional corporation.</p>',
      '<h3>What skills or qualities do you believe are essential for success in business development and sales at a tech company like Voltaware?</h3>',
      '<p class="justified">Success in business development at a tech company such as Voltaware is based on a combination of numerous key skills and qualities. Among all of them, I would highlight the following ones:</p>',
      `<ul class="with-margin-list">
        <li>First of all, I think strategic thinking is crucial. This involves being able to conduct thorough
market research to identify potential clients and understand the market needs.</li>
        <li>Then I would highlight excellent sales skills - this means not only the ability to identify and
qualify leads but also to manage the entire sales process from prospecting to closing.</li>
        <li>Furthermore, it is important to have solid technical knowledge, including a deep understanding
of the company's products, their features, technical specifications and how they compare to
competitors.</li>
        <li>And last but not least, it is multitasking skills. Business development roles often require juggling
multiple responsibilities simultaneously. This means effectively managing various client
accounts, coordinating with different internal teams, and staying on top of your lead generation.</li>
      </ul>`,
      '<h3>What strategies do you find most effective for building and maintaining strong relationships with clients?</h3>',
      '<p class="justified">I find that effective communication, trust, and a genuine interest in understanding the client\'s needs are key strategies. Actively listening to their concerns, providing personalized solutions, and consistently delivering value are also important. Additionally, maintaining open lines of communication, being responsive, and proactively addressing any issues that may arise help foster a positive client experience.</p>',
      '<h3>What is something interesting or surprising about you that most people at work might not know?</h3>',
      '<p class="justified">One interesting and perhaps surprising aspect about me that most people at work might not know is my deep passion for music. I enjoy exploring various genres, from classical to jazz, pop to rock, and everything in between. Beyond just listening, I have a love for singing that has been a significant part of my life. For seven years, I was a dedicated member of a choir. Music has always been more than just a hobby for me; it\'s a fundamental part of my life. It\'s been a source of joy, discipline, and creative expression.</p>',
    ],
    tags: [],
    related: [],
  },

  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-42.jpeg',
    date: '09 February 2024',
    link: '/blog/2024-02-09/meet-the-team-william-lopes',
    title: 'Meet the team | William Lopes',
    preview:
      "Welcome to another edition of our 'Meet the Team' interview series! Today, we're pulling back the curtain to showcase another incredible individual who forms the backbone of Voltaware. You may already be familiar with our groundbreaking technology, but it's time to get up close and personal with the brilliant minds who bring it to life.",
    text: [
      '<p class="justified">Please meet Lopes - our Lead Machine Learning Engineer, who joined Voltaware 7 years ago and played a crucial role in creating the products that Voltaware offers now.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-42.jpeg" alt="Meet the team | William Lopes" /></div>',
      '<h3>Can you tell us about your journey here at Voltaware?</h3>',
      '<p class="justified">I joined the Voltaware team in 2017, at the same time as I was finishing my master\'s degree. It was an exciting time as the main disaggregation product was still in its early stages, so I had the opportunity to actively participate in designing and implementing the project\'s machine learning backend infrastructure. This experience helped me to grow as a professional both with the product and within the company. I believe over time, my familiarity with the stack, requirements and solutions allowed me to get my current position as tech lead of the Machine Learning engineering team. </p>',
      '<h3>Why did you decide to specialise in Machine Learning? What makes it so interesting for you?</h3>',
      '<p class="justified">I was fortunate to start my career as a developer at a computer vision software company. Thus, I had to learn some Machine Learning as part of my job.</p>',
      '<p class="justified">I found machine learning interesting from the beginning, but it was not until around 2012, with the rise of convolutional neural networks, that I realized its true potential and how revolutionary it could be. These new networks enabled us to implement systems that were previously incredibly difficult. That is when I decided to specialize in machine learning, and my fascination with it has only grown since then.</p>',
      '<p class="justified">In my opinion, AI is the most exciting area to study and work in right now. It is amazing to see how it is transforming industries every day, and I am grateful to be a part of it.</p>',
      '<h3>Run us through a typical day at Voltaware</h3>',
      '<p class="justified">A typical day for me involves engaging in numerous technical discussions, planning sessions, and design tasks. Additionally, I spend a considerable amount of time coding in Python, and sometimes collaborating through pair programming. Another significant aspect of my day involves conducting thorough code reviews and providing constructive feedback to ensure the quality and efficiency of our codebase.</p>',
      '<h3>What do you enjoy the most about working at Voltaware?</h3>',
      '<p class="justified">Voltaware is a highly dynamic company, constantly exploring new ideas and projects, which keeps us challenged to learn and meet our clients\' evolving needs. Additionally, I value the diverse global team, with members from various technical backgrounds. This diversity fosters unique perspectives, enriching our problem-solving approach and leading to innovative solutions.</p>',
      '<h3>Can you tell us about your professional goals this year?</h3>',
      '<p class="justified">My goal is to continually enhance our machine learning backend stack, refine our internal processes, and optimize communication channels. By doing so, we aim to consistently deliver high-quality products that meet and exceed our customers\' expectations.</p>',
      '<h3>Outside of work, what are some of your hobbies or interests that you\'re passionate about?</h3>',
      '<p class="justified">My main hobby is reading history books. I am especially passionate about ancient Roman and Greek history.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-41.jpeg',
    date: '11 October 2023',
    link: '/blog/2023-10-11/voltawares-partership-with-burgenland-energie',
    title: 'Voltaware\'s Partnership with Burgenland Energie: Energy Insights for Prosumers',
    preview:
      'Burgenland Energie chooses Voltaware to deploy advanced energy insights in Austria.',
    text: [
      '<p class="justified">Voltaware is excited to announce a strategic partnership with the Austrian utility Burgenland Energie, which marks a significant step to empowering Austrian consumers to optimize their energy consumption and reduce their environmental footprint.</p>',
      `<div style="display: flex; flex-direction: column; align-items: center">
        <img src="/img/del/blog-img-41.jpeg" alt="Voltaware's Partnership with Burgenland Energie: Advanced Energy Insights" />
        <p class="figure-caption">Left: Stephan Sharma, CEO, Burgenland Energie<br/>Right: Sergey Ogorodnov, CEO, Voltaware</p>
      </div>`,
      '<h3 class="green">The brief</h3>',
      '<p class="justified">Burgenland Energie is a public Austrian utility operating in the state of Burgenland, servicing 200,000+ residential customers. The federal state Burgenland set a goal to become carbon neutral by 2030, making it one of the first carbon neutral and energy independent regions in the world. As a GreenTech utility and a market leader in wind and PV, Burgenland Energie has extensive know-how in climate neutral and self-sufficient energy solutions for customers.</p>',
      '<p class="justified">Burgenland Energie recognizes that empowering its customers with innovative tools and insights is essential in the rapidly evolving energy landscape. As part of its mission to drive sustainability and provide value to its customers, the utility has chosen to partner with Voltaware and provide their customers with unprecedented insights into their energy usage.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/burgenland-energie-usage.png" alt="Energy usage" /></div>',
      '<h3 class="green">The partnership</h3>',
      '<p class="justified">Voltaware and Burgenland Energie will embark on a comprehensive 5-year rollout plan designed to bring cutting-edge energy insight technology directly into the homes of tens of thousands of their customers. Burgenland Energie is bundling Voltaware sensors in a prosumer-tailored offer including solar panels and batteries, which is directly advertised to consumers via the Austrian newspaper Kronen Zeitung.</p>',
      '<p class="justified">By leveraging Voltaware’s state-of-the-art energy monitoring sensor combined with appliance-level insights, Austrian consumers are able to engage with their energy consumption and production directly through Burgenland’s mobile app <em>BE Spare Flo</em> - in real-time.</p>',
      '<p class="justified">Burgenland Energie will also deploy Voltaware’s smart meter-only insights to engage all their customers with their energy use at scale, and incentivise them to upgrade to a real-time BE Spare Flo sensor product.</p>',
      `<div style="display: flex; flex-direction: column; align-items: center">
        <img src="/img/del/burgenland-energie-spareflo-app.png" alt="BE Spareflo mobile app" />
        <p class="figure-caption">Be Spare Flo mobile app</p>
      </div>`,
      '<p class="justified">The partnership between Burgenland Energie and Voltaware represents a mutual commitment to delivering value to customers, promoting sustainability, and supporting Austria’s energy transition. Both companies look forward to the positive impact this collaboration will have on the Austrian market and its customers, and subsequently extend it to the German market.</p>',
      `<div>
        <p>Sergey Ogorodnov, Voltaware, CEO:</p>
        <blockquote class="citation justified" cite="Sergey Ogorodnov, Voltaware, CEO">
          The partnership with Burgenland Energie highlights the necessity for Austrian consumers to understand and optimise their energy use. We are delighted to work with Burgenland Energie - an innovative, agile utility looking to disrupt the energy industry and greatly contribute to the netzero transition.
        </blockquote>
      </div>`,
      `<div>
        <p>Stephan Sharma, CEO of Burgenland Energie:</p>
        <blockquote class="citation justified" cite="Stephan Sharma, CEO of Burgenland Energie">
          This partnership represents a significant step forward in our commitment to delivering value to our customers and promoting sustainable energy practices. Together with Voltaware, we are confident that we can help our customers optimize their energy use and contribute to a greener, more efficient future.
        </blockquote>
      </div>`,
      '<div style="display: flex; justify-content: center"><img src="/img/del/burgenland-energie-and-voltaware-crew.jpeg" alt="Burgenland Energie and Voltaware Crew" /></div>',
      '<h3 class="green">About Burgenland Energie</h3>',
      '<p class="justified">Burgenland Energie is a public Austrian utility operating in the state of Burgenland, serving a diverse customer base with energy solutions tailored to their needs. Burgenland Energie is known for its focus on renewable energy sources, such as wind, solar, and biomass, and has made significant strides in transitioning to green energy. Committed to promoting sustainable energy practices and environmental responsibility, Burgenland Energie seeks to empower its customers to make energy-efficient choices. (<a class="dark-green" target="_blank" href="https://burgenlandenergie.at">https://burgenlandenergie.at</a>)</p>',
      '<h3 class="green">About Voltaware</h3>',
      '<p class="justified">Voltaware is a London-based energy startup revolutionising the way people interact with their energy use, and gives them the visibility to optimise their energy use. Using data from smart meters or the Voltaware sensors, Voltaware provides a range of AI-driven appliance-level insights, helping consumers take the best actions to save energy and costs. (<a class="dark-green" target="_blank" href="https://voltaware.com">https://voltaware.com</a>)</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-40-cover.png',
    date: '24 August 2023',
    link: '/blog/2023-08-24/voltawares-partership-with-net0',
    title: 'Voltaware\'s Partnership with Net0: An In-Depth Case Study',
    preview:
      'Voltaware and Net0 have forged a partnership in the face of rising electricity prices and the pressing need for carbon reduction.',
    text: [
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-40.png" alt="Voltaware\'s Partnership with Net0: An In-Depth Case Study" /></div>',
      '<p class="justified">Net0, with its AI-driven data, empowers users to monitor and track their energy use down to the appliance level. Together, they aim to offer actionable insights into carbon reduction, optimizing energy spending, and promoting greener businesses and buildings. This collaboration provides businesses with a comprehensive solution to oversee, diminish, and report their carbon emissions while maximizing energy efficiency and cost savings.</p>',
      '<h3>The Challenge</h3>',
      '<p class="justified">Electricity prices have surged, affecting millions globally. There\'s a pressing need for actionable insights to help businesses and households reduce energy costs and carbon footprints. Voltaware\'s AI-driven insights and Net0\'s comprehensive carbon accounting platform address this challenge head-on.</p>',
      '<h3>The Solution</h3>',
      '<p class="justified"><strong>Voltaware\'s</strong> AI algorithm disaggregates energy use down to each appliance, providing real-time insights. Features include:</p>',
      `<ul class="with-margin-list">
        <li>VoltaScore: Energy efficiency score based on historical consumption, P2P comparisons and tariff information driving engagement via gamification.</li>
        <li>Appliance status checks when out of home to prevent customers from leaving critical appliances being left on.</li>
        <li>Intelligents alerts on appliance overconsumption which help customers to detect inefficiencies or misused appliances.</li>
        <li>Elderly Monitoring alerts to the next of kin to non-intrusively monitor an elderly relative living alone and track inactivity levels.</li>
      </ul>`,
      '<p class="justified"><strong>Net0</strong> offers an AI-powered, automation-first carbon accounting platform, packed with sophisticated tailored tops. This allows businesses to:</p>',
      `<ul class="with-margin-list">
        <li>Full-spectrum emissions tracking, ensuring that businesses have a comprehensive view of their carbon footprint across all operational levels.</li>
        <li>Real-time Carbon Reporting, now using advanced analytics, businesses can access real-time carbon emission data.</li>
        <li>Industry-specific solutions that are tailored to the unique challenges and requirements of each industry.</li>
        <li>Cutting-edge Financial Strategies and Tools that ensure that profitability and environmental responsibility go hand in hand.</li>
      </ul>`,
      `<div>
        <p>Sergey Ogorodnov, CEO:</p>
        <blockquote class="citation justified" cite="Sergey Ogorodnov, CEO">
          Net0 provides a scalable carbon emissions measurement tool which combines greatly with our energy insight platform. Our utility customers can now use both softwares to unlock new business opportunities and achieve their climate targets. We look forward to working with Net0 across our key European markets.
        </blockquote>
      </div>`,
      '<h3>The Impact</h3>',
      '<p class="justified">Onda Più\'s Success Story: Onda Più, an energy retailer in Sicily with a customer base of 50,000, integrated Voltaware\'s solutions:</p>',
      `<ul class="with-margin-list">
        <li>Deployed over 10,000 rebranded Voltaware sensors, incorporating real-time actionable insights into their customer app.</li>
        <li>Achieved an 8% annual energy bill reduction for their customers.</li>
        <li>Witnessed a fourfold decrease in customer churn rate.</li>
        <li>Observed a 5x increase in customer engagement on the Onda app.</li>
        <li>Enhanced brand awareness and loyalty, setting themselves apart from traditional utilities.</li>
      </ul>`,
      `<div>
        <p>François Gruber-Magitot, Voltaware, Head of Business Development:</p>
        <blockquote class="citation justified" cite="François Gruber-Magitot, Voltaware, Head of Business Development">
          Deploying Voltaware across Onda customers has helped them to take command of their energy use while democratizing energy literacy in Southern Italy. This clearly shows that actionable energy insights are the key to reducing energy use and greenhouse gas emissions at scale. It also strengthened Onda's position in the market as a challenger utility with a differentiated customer proposition.
        </blockquote>
      </div>`,
      '<p class="justified">The alliance between Voltaware and Net0 transcends the conventional business partnership. It stands as a testament to the transformative power of technology-driven solutions in the face of today\'s environmental challenges. As businesses worldwide grapple with the intricate dance of fiscal responsibility and environmental stewardship, this collaboration offers a compelling narrative of success.</p>',
      '<p class="justified">The metamorphosis of Onda Più, under the aegis of this alliance, serves as a testament to the transformative power of technology and collaboration. But it\'s more than just a success story; <u>it\'s a call to arms</u>. Voltaware and Net0 partnership sends a clear message to businesses everywhere: the path to sustainability is paved with innovation, and the journey, while challenging, is worth every step.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-39.jpg',
    date: '19 July 2023',
    link: '/blog/2023-07-19/meet-the-team-antonio-lago',
    title: 'Meet the team | Antonio Lago',
    preview:
      'You may be familiar with our technology but do you know the brains behind it? Every now and then, we’d like to bring the Voltaware team closer to you and what makes who we are.',
    text: [
      '<p class="justified">Meet Antonio Lago - our Senior Software Developer and a key member of the Voltaware Development Team. Antonio joined the team 8 years ago and contributed to guide the architecture of the system and to be a bridge between development team and business people. He shares his journey at Voltaware with us.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-39.jpg" alt="Meet the team | Antonio Lago" /></div>',
      '<h3>Can you tell us about your role at Voltaware and how does your typical day look like?</h3>',
      '<p class="justified">My role as a senior software developer is to support the development team, guide the architecture of the system and to be a bridge between development team and business people.</p>',
      '<p class="justified">Throughout my day I have several meetings with technical teams and business people. In these meetings we discuss technical solutions to business propositions and new development efforts that will be supported by our product architecture. I review code, guide technical sessions and envision new technical improvements that we can work on.</p>',
      '<h3>How did you hear about Voltaware and what motivated you to join the team?</h3>',
      '<p class="justified">I met one of the senior members of Voltaware at the very beginning of the company and I have been introduced to the idea of energy disaggregation, something that I had never heard before, and found it fascinating, since then I joined the team to help create an amazing product for our customers.</p>',
      '<h3>How do you keep up with new data engineering technologies and can you apply them in your daily tasks?</h3>',
      '<p class="justified">Since my young age I was involved in computer programming, reading old programming magazines where the code was printed in magazine pages, very different from today\'s possibilities of learning. Now we have information everywhere, tutorials, digital books, videos, podcasts.</p>',
      '<p class="justified">My main sources are big tech engineering blogs, conferences, open source news letters and good and old books. That helps me to know new tools and techniques that we can play and test and if it fits our needs we can apply it to the product architecture.</p>',
      '<h3>How are you keeping your team engaged and aligned towards the company goals?</h3>',
      '<p class="justified">What drives the development team is to see the product helping people and the environment and making our customers aware of their energy usage choices so they can opt to have a more conscious energy consumption pattern.</p>',
      '<p class="justified">We also like to understand the best practices of the tech world, we keep learning new things and applying it to Voltaware product architecture, these are the main things I like to do to keep the team engaged in the company goals.</p>',
      '<h3>Can you tell us about your personal and professional goals this year?</h3>',
      '<p class="justified">My personal goals this year are to keep learning Italian and improve my photography skills learning to use a semi-professional camera. My professional goals are to keep improving the development team communication and collaboration and to drive them to deliver the best solutions for our customers.</p>',
      '<h3>What do you like to do outside work?</h3>',
      '<p class="justified">Outside of work I like to do many things when I have time, among them I like road trips, cycling, hiking and reading philosophy books.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-38-cover.png',
    date: '29 March 2023',
    link: '/blog/2023-03-29/voltaware-wins-ita-3-challenge',
    title: 'Voltaware wins ITA 3.0 challenge and partners with Vodafone',
    preview:
      "Voltaware’s Energy Insight Platform has been successfully selected to help Vodafone’s employees to sustainably work from home.",
    text: [
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-38.png" alt="Voltaware wins ITA 3.0 challenge and partners with Vodafone" /></div>',
      '<p class="justified">Since energy prices significantly increased over the last few months, Vodafone has been on a mission to help its employees and customers optimize their energy use. The ITA 3.0 accelerator is Vodafone’s internal accelerator to select the most promising tech companies to achieve this goal.</p>',
      '<p class="justified">Voltaware has been selected by Vodafone to offer its sensor-based product to Vodafone employees across the UK, Germany and South Africa. Voltaware’s platform will provide appliance-level insights to help them optimize their energy use in real-time, reduce carbon footprint and identify inefficient appliances.</p>',
      '<p class="justified">The deployment will also look at providing Voltaware’s smart meter-based products across Vodafone’s wider customer base in the UK and Germany, which will provide advanced energy insights to engage them with netzero. This will also unlock a path to upselling energy saving products across Vodafone stores.</p>',
      `<div>
        <p>Katy Schofield, Capacity and Configuration Manager, Vodafone UK:</p>
        <blockquote class="citation justified" cite="Katy Schofield, Capacity and Configuration Manager, Vodafone UK">
          We won the ITA Programme in a joint venture that looked to find a solution to ‘sustainably working from home’ reporting on Vodafone’s Home and Blended workers energy usage from home. This in turn will assist Vodafone in reporting on our ESG Scope 3 Emissions and Taxonomy Regulations.<br/>
          <div class="citation-paragraph">&nbsp;</div>We continue our partnership across operations and countries to look for ways in which we can incorporate the Voltaware solutions into Vodafone operations for our staff and customers.
        </blockquote>
      </div>`,
      `<div>
        <p>Sergey Ogorodnov, CEO, Voltaware:</p>
        <blockquote class="citation justified" cite="Sergey Ogorodnov, CEO, Voltaware">We are excited to see that Vodafone is now moving into the energy space and is the first telecom to help their employees address the challenges associated with the surging energy prices. We look forward to supporting Vodafone’s employees and customers and jointly advancing the energy transition.</blockquote>
      </div>`,
      '<p class="justified"><strong>About Vodafone:</strong> Vodafone is a multinational leading telecommunications company keeping society connected and building a digital future for everyone.</p>',
      '<p class="justified"><strong>About Voltaware:</strong> We are a UK-based Energy Insight technology company helping telecoms, utilities and banks to support and engage their customers, while democratizing energy literacy.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-37-cover.png',
    date: '22 February 2023',
    link: '/blog/2023-02-22/voltaware-joins-aldar-scale-up',
    title: 'Voltaware joins Aldar Scale Up 3 in the UAE',
    preview:
      "Voltaware has been selected to be a part of ALDAR Scale Up 3, accelerated by startAD, with the view to transform real estate in the UAE.",
    text: [
      '<p class="justified">Aldar Scale Up is a key initiative furthering innovation at Aldar and has been developed in partnership with startAD, the Abu Dhabi-based global startup accelerator, powered by Tamkeen and anchored at NYU Abu Dhabi.</p>',
      '<p class="justified">Committed to fostering innovation, in line with the UAE’s National Innovation Strategy, Aldar has developed a framework to drive forward innovative practices. This year\'s focus is on proptech startups building a smarter, greener, and safer real estate industry.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-37.png" alt="Voltaware joins Aldar Scale Up 3 in the UAE" /></div>',
      '<p class="justified">Voltaware was selected out of more than 280 global proptech startups to leverage our ground-breaking technology to transform real estate in the UAE.</p>',
      '<p class="justified">Thanks to our expertise in the use of AI applied to energy monitoring, we are able to help UAE tenants optimise their energy use by offering:</p>',
      `<ul class="with-margin-list">
        <li>Real-time energy monitoring</li>
        <li>Appliance level check insights </li>
        <li>Monthly energy reports</li>
        <li>Personalised tips & recommendations to save energy</li>
      </ul>`,
      '<p class="justified">Aldar Scale up is a 4-week program that helps startups to scale their business in the UAE, access a strong network in the region, and have the opportunity to win commercial contracts with market leaders.</p>',
      '<p class="justified">Stay tuned for our progress on the programme and <a class="dark-green" href="https://voltaware.com/request-a-demo" target="_blank">request a demo</a> if you also want to help your customer save energy.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-36-cover.png',
    date: '03 February 2023',
    link: '/blog/2023-02-03/practical-guide-ii',
    title: 'Practical guide to save energy - II',
    preview:
      "We have compiled 10 tips and recommendations to help you save energy during winter.",
    text: [
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-36.png" alt="Practical guide to save energy - II" /></div>',
      '<p class="justified">In this guide, we\'ll help you find ways to reduce your energy costs during Winter.</p>',
      '<p class="justified">We\'ll start by reviewing some tips to make your appliances more efficient and why it\'s important to understand the numbers. Finally, we\'ll talk about a few basic upgrades you can make to reduce your energy usage and save money.</p>',
      '<h3 class="title bold no-space dark-green">Taking immediate action</h3>',
      '<span class="darker">1. Standby</span>',
      '<div class="blog-post-30"><p class="justified">You can quickly and easily reduce energy and costs by turning off your appliances on standby.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Switch off appliances at the plug<br/><span class="tip-label">Savings</span>up to 50 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/tech-standby-icon.png" width="50px" alt="Energy Tips - Standby" /></div></div></div>',
      '<span class="darker">2. Heating</span>',
      '<div class="blog-post-30"><p class="justified">Putting your thermostat a few degrees lower will contribute to reducing energy costs.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Reduce heating to 18-20 °C<br/><span class="tip-label">Savings</span>up to 200 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/tech-heating-icon.png" width="50px" alt="Energy Tips - Heating" /></div></div></div>',
      '<span class="darker">3. Boiler</span>',
      '<div class="blog-post-30"><p class="justified">Reducing boiler temperature, especially in rooms not used, will help you save energy & costs.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Switch off heater at the plug<br/><span class="tip-label">Savings</span>up to 150 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/tech-boiler-icon.png" width="50px" alt="Energy Tips - Boiler" /></div></div></div>',
      '<span class="darker">4. Showers</span>',
      '<div class="blog-post-30"><p class="justified">Electric showers can be very costly, try minimizing shower times to optimize your bill.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Reduce to 4 min showers<br/><span class="tip-label">Savings</span>up to 100 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/tech-showers-icon.png" width="50px" alt="Energy Tips - Showers" /></div></div></div>',
      '<span class="darker">5. Close your curtains</span>',
      '<div class="blog-post-30"><p class="justified">Closing curtains and blinds is an effective way for keeping the heat inside the house during the cooler hours of the day.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Cover your windows at dusk<br/><span class="tip-label">Savings</span>Reduce heat loss by 15%</div><div class="energy-tip-img"><img src="/img/technology/tech-curtains-icon.png" width="50px" alt="Energy Tips - Close your curtains" /></div></div></div>',
      '<h3 class="title bold no-space dark-green" style="margin-top: 60px">Longer-term saving tips</h3>',
      '<span class="darker">1. Double glaze your windows</span>',
      '<div class="blog-post-30"><p class="justified">With energy prices rising quickly, installing double glazing is a cost-effective way to keep your home warm.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Double-glazed windows<br/><span class="tip-label">Savings</span>50 - 80 GBP / month</div><div class="energy-tip-img"><img src="/img/technology/tech-double-glaze-icon.png" width="50px" alt="Energy Tips - Double glaze your windows" /></div></div></div>',
      '<span class="darker">2. Block draughty gaps</span>',
      '<div class="blog-post-30"><p class="justified">A draught is a cold air leakage from your home through gaps. It reduces the energy efficiency of your heating system, so it uses more to keep your home warm, costing you more money.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Spot windows and doors draughts<br/><span class="tip-label">Savings</span>60 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/tech-draughty-gaps-icon.png" width="50px" alt="Energy Tips - Block draughty gaps" /></div></div></div>',
      '<span class="darker">3. Get your boiler serviced</span>',
      '<div class="blog-post-30"><p class="justified">Regular maintenance of your boiler helps it run more efficiently, reduce the risk of costly unexpected breakdowns or repairs and gives you peace of mind.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Get your boiler serviced regularly<br/><span class="tip-label">Savings</span>80 - 110 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/tech-maintenance-icon.png" width="50px" alt="Energy Tips - Get your boiler serviced" /></div></div></div>',
      '<span class="darker">4. Add carpets or rugs</span>',
      '<div class="blog-post-30"><p class="justified">Carpet keeps the room warmer and stops cold air rising from the floor, so you will use less energy keeping the room warm.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Get a wool rug<br/><span class="tip-label">Savings</span>up to 110 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/tech-carpet-icon.png" width="50px" alt="Energy Tips - Add carpets or rugs" /></div></div></div>',
      '<span class="darker">5. Install a Smart Meter</span>',
      '<div class="blog-post-30"><p class="justified">Powered with Voltaware’s technology, you can receive <a class="dark-green" href="https://voltaware.com/blog/2023-01-18/advanced-smart-meters-based-insights" target="_blank">actionable insights</a> to help you optimize your energy use.</p><div class="energy-tip-container higher"><div class="energy-tip"><span class="tip-label">Tip</span>Get an in-home device<br/><span class="tip-label">Savings</span>up to 8% on your energy bills</div><div class="energy-tip-img"><img src="/img/technology/tech-smart-meter-icon.png" width="50px" alt="Energy Tips - Install a Smart Meter" /></div></div></div>',
      '<p class="justified" style="margin-top: 60px">Home upgrades can help you save money and energy. Simple changes like replacing your shower head, adding rugs or servicing your boiler can help you cut down on energy usage.</p>'
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-35.png',
    date: '18 Jan 2023',
    link: '/blog/2023-01-18/advanced-smart-meters-based-insights',
    title: 'Advanced smart meter-based insights',
    preview:
      'Voltaware presents its new Energy Insights using smart meters to be deployed at scale by Utilities, Banks, Telecoms.',
    text: [
      '<p class="justified">Complex monthly energy bills simply aren\'t enough for customers faced with rising energy costs. Without having real tools and insights to optimise their energy use and reduce costs, customers are looking for simple appliance-level insights to be in place during the Winter.</p>',
      '<p class="justified">By collecting low resolution smart meter data, Voltaware has developed Monthly and Weekly energy disaggregation-based reports to provide a detailed view of customers’ energy use.</p>',
      '<p class="justified">Utilities, Banks and Telecoms will now be able to seamlessly offer their customers personalised energy tips & recommendations, carbon footprint advice, appliance-level insights - avoiding the need for complex hardware installation programmes.</p>',
      `<div class="xs-screen-wrap" style="display: flex; justify-content: center; align-items: center;">
        <ul class="with-margin-list items-padded-40" style="margin-right: 35px;">
          <li>Monthly/Weekly disaggregation reports with engaging energy insights</li>
          <li>Scalable, only requires smart meter data</li>
          <li>Cost effective, can be deployed immediately to support your customers during the Winter</li>
          <li>Opens a path to upselling and monetisation opportunities</li>
        </ul>
        <img src="/img/technology/low-res-app.png" width="400px" alt="Low Resolution App" />
      </div>`,
      '<p class="justified">The opportunities for energy companies don’t end here: As consumers look to better manage their energy consumption over the medium-term, energy companies should also look to engage with further upselling opportunities.</p>',
      '<p class="justified">Voltaware’s AI-powered insights are being deployed across household appliance efficiency monitoring use cases, as well as non-intrusive activity monitoring solutions for the vulnerable and elderly.</p>',
      '<p class="justified">Get in touch with our Sales Team today to arrange a <a class="dark-green" href="https://voltaware.com/request-a-demo">product demo</a>.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-34.png',
    date: '10 Nov 2022',
    link: '/blog/2022-11-10/voltaware-enlit-2022',
    title: 'Voltaware will be at Enlit Frankfurt 2022',
    preview:
      'We are delighted to be exhibiting at ENLIT Frankfurt 2022 and would love for you to join us.',
    text: [
      '<p class="justified">Enlit Europe brings together all established players, external disruptors and innovative start-ups to seize current opportunities, spotlight future ones, and inspire the next generation to participate in the energy transition.</p>',
      '<p class="justified">At the European edition, the Enlit community will come together <strong>in Frankfurt from 29 November - 1 December 2022</strong>, to meet and inspire each other and to develop their discussions and actions to take steps forward in the energy transition.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-34.png" alt="Enlit 2022" /></div>',
      '<p class="justified">Our mission at Voltaware has always been to provide energy insights helping customers change the way they interact with their energy use. With the global increase of energy bills, this year has been hugely challenging for everyone. In order to help your customers save energy, we have developed scalable energy products ready to be deployed this Winter. </p>',
      '<p class="justified">We cannot wait to show you how we have been working with utilities and their customers across the globe to democratize energy literacy and advance the energy transition.</p>',
      '<p class="centered"><a class="button" target="_blank" href="https://www.enlit-europe.com/exh-invite-link">Get your free pass</a></p>',
      '<p class="centered"><strong>Come visit us at stand 12.1.C180 at ENLIT Frankfurt.</strong> <a class="dark-green" target="_blank" href="https://calendly.com/lets_meet_at_enlit_frankfurt_2022/meeting-with-voltaware">Book a meeting now!</a></p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-33.png',
    date: '26 Oct 2022',
    link: '/blog/2022-10-26/voltaware-net0-case-study',
    title: 'Voltaware & Net0 case study',
    preview:
      'Voltaware partners with Net0 to unlock opportunities for businesses to save energy and reduce carbon emissions.',
    text: [
      '<p class="justified">Net0 is an AI-powered, automation-first carbon accounting platform for companies to comprehensively measure, reduce and report on their carbon emissions, and get recognised with carbon neutral certificates.',
      '<p class="justified">Using Net0 carbon accounting tools and emissions reports, businesses can precisely measure and reduce their Scope 1,2 and 3 emissions. They can also reach netzero faster and become greener by accessing 140+ global certified programmes.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-33.png" alt="Voltaware & Net0 case study" /></div>',
      '<p class="justified">Combined with Voltaware’s AI driven energy insights down to each appliance, businesses can now have a disaggregated view of their assets and make them more energy efficient.</p>',
      '<p class="justified">Voltaware’s Energy Monitoring Dashboard also provides predictive maintenance for businesses to monitor their most critical assets. Customisable reports and alerts are available to energy managers to make clear decisions with a view to reduce their energy use and costs.</p>',
      '<p class="justified">This partnership will unlock a complete solution for businesses looking to get visibility over their energy use and reduce both costs and carbon emissions.</p>',
      '<p class="justified">Get in touch with <a class="dark-green" href="mailto:enquiries@voltaware.com">enquiries@voltaware.com</a> or <a class="dark-green" href="mailto:dmitry@net0.com">dmitry@net0.com</a> for a live demo. </p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-32.jpg',
    date: '20 Oct 2022',
    link: '/blog/2022-10-20/meet-the-team-gian-paolo',
    title: 'Meet the team | Gian Paolo',
    preview:
      'You may be familiar with our technology but do you know the brains behind it? Every now and then, we’d like to bring the Voltaware team closer to you and what makes who we are.',
    text: [
      '<p class="justified">Meet Gian Paolo - our most international Product Manager and a key member of Voltaware Product team. Gian joined the team 5 years ago and contributed to design Voltaware’s current management tools, new products and features for our customers. He shares his journey at Voltaware with us.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-32.jpg" alt="Meet the team | Gian Paolo" /></div>',
      '<h3>Tell us about your role at Voltaware and how does your typical day look like.</h3>',
      '<p class="justified">My current role is Product Manager, but I’ve also worked and continue working as Product Owner depending on the project.</p>',
      '<p class="justified">On a daily basis, you’ll see me taking care of the product backlog: creating tasks or bug cards, prioritizing the workflow, creating User Stories or any internal documentation page, making sure that all of our products are up and running. Also, testing new features that our developers have completed to make them available for our customers as soon as possible.</p>',
      '<p class="justified">You can also find me on Google Meets or Microsoft Teams attending calls, either with our development team based in Brazil, in order to supervise the work that has been done, getting an overview of any issues we might have or any new features available to test. I also work with the business team by assisting them with any technical or product-oriented question that customers might have.</p>',
      '<h3>Did you have previous experience before Voltaware?</h3>',
      '<p class="justified">I started working at Voltaware as soon as I finished my double degree in Computer and Industrial Engineering, so I consider it as my first real job. However, while I was studying, I worked a few months during Summer in a plastic bag factory in order to understand the whole production process and find ways to improve it. I also assisted the department of scholar studies in my university, supporting them on digitalising students’ archives.</p>',
      '<h3>Why do you like working at Voltaware?</h3>',
      '<p class="justified">What I really like about working at Voltaware is that this company is always moving, always tackling new challenges, new ideas, new products - and all these while maintaining and upgrading our core products. It’s an extremely fast paced environment, where we meet lots of new clients, from any country, so speaking different languages is really useful here.</p>',
      '<p class="justified">I like the fact that I work with people from different nationalities and backgrounds: engineering, business, even people that studied political science…</p>',
      '<p class="justified">I also enjoy having responsibilities and from the moment I started working at Voltaware, I felt that I had the opportunity to do something great. Working at Voltaware for the past 5 years means that I am one of the seniors in the company, so I have to lead by giving a good example!</p>',
      '<p class="justified">The mindset that prevails is that it’s ok if you make a mistake, you can always learn, improve and come back stronger.</p>',
      '<h3>What are your hobbies and interests outside work?</h3>',
      '<p class="justified">I really like outdoor activities, going to the mountains to walk large distances. It’s something I’ve always really enjoyed and now that I have a dog I do it even more frequently.</p>',
      '<p class="justified">I’ve been running for a while now and I am currently training to do a half marathon in Bilbao (Spain) in October. We’ll see what comes next after that one!</p>',
      '<p class="justified">I really love food so going out to try new restaurants or going back to our good old favourites in Madrid, where I live, is something I really enjoy doing with my wife as well as going out for coffees after lunch while strolling around the city.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-31-cover.png',
    date: '17 Ago 2022',
    link: '/blog/2022-08-17/voltaware-chosen-to-revolutionise-australia-energy-sector',
    title: "Voltaware chosen to revolutionise Australia's energy sector",
    preview:
      "New batch of startups set to revolutionise Australia’s energy sector through the EnergyLab Scaleup Program.",
    text: [
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-31-cover.png" alt="Voltaware chosen to revolutionise Australia\'s energy sector" /></div>',
      '<p class="justified">Eleven of the world’s best late-stage energy startups are now on a fast-track to commercialisation in the Australian market as members of EnergyLab’s third Scaleup Program cohort.</p>',
      '<p class="justified">Originally launched in 2020, the Scaleup Program offers participating startups an opportunity to collaborate with energy companies, investors, industry leaders, other founders, and mentors from the EnergyLab network to help unlock their next wave of growth.</p>',
      '<p class="justified">This year, EnergyLab is delivering the program in partnership with four of Australia’s largest and most innovative energy companies: Transgrid, Endeavour Energy, Amber and Lumea. Transgrid manages the regulated high voltage and electricity transmission network in NSW and ACT, while Endeavour Energy distributes electricity to 2.6 million households and businesses across Sydney’s Greater West, Blue Mountains, Southern Highlands, the Illawara, and South Coast regions.</p>',
      '<p class="justified">Transgrid and Endeavour Energy have both rejoined as program partners this year while Amber, a graduate of EnergyLab’s 2018 Acceleration Program, has come onboard for the first time. Amber is an electricity retailer powering the renewable revolution through real-time wholesale prices and automation of batteries and other smart devices.</p>',
      '<div><p>Chris Thompson, CEO of Amber:</p><blockquote class="citation" cite="Chris Thompson, CEO of Amber">Accelerating this renewable revolution has never been more important, and we know from experience how great the EnergyLab program is for getting traction and finding the right supporter and partners. We’re thrilled to be working with amazing new startups in the program and supporting here.</blockquote></div>',
      '<div><p class="justified">Lumea is a recently formed renewable infrastructure and energy services company within Transgrid, operating in the unregulated market across the NEM. Lumea’s General Manager, Innovation and New Ventures, Daphne Yao says she is excited to be supporting Amber Kinetics for the 2022 program.</p><blockquote class="citation" cite="Daphne Yao">New energy storage solutions will be crucial to supporting increased electrification of industries through renewable generation. We’re excited to see how Amber Kinetics develops their kinetic energy storage system (KESS) which could become a really interesting adjunct to the large-scale batteries which we’re currently connecting to the grid.</blockquote></div>',
      '<p class="justified">Leanne Pickering, Chief Customer & Strategy Officer, Endeavour Energy said, “As a partner for the second year running in EnergyLab’s Scaleup Program, Endeavour Energy looks forward to collaborating with EnergyLab to develop start-ups that offer innovative solutions to increase energy affordability for our customers and enhance the reliability and resilience of our electricity network now and in the future. Through our participation this year we are looking to work with partners to accelerate the delivery of our strategic priorities around network digitisation, supporting e-mobility and enabling our customers to better monitor and control electricity consumption and costs.”</p>',
      '<p class="justified">To select this year’s cohort, EnergyLab worked with each partner to identify priority areas for innovation and then recruited startups best-suited to solving some of the most pressing problems across the energy industry.</p>',
      '<p class="justified">The innovations represented in this year’s cohort are diverse and include a range of technologies that will help put Australian energy companies on the global playing field. The cohort includes companies working on asset management and network digitisation technologies, consumer engagement models and network communications innovations, as well as several startups working on electric vehicle infrastructure and adoption.</p>',
      '<p class="justified">EnergyLab’s extensive network of over 300 industry leaders, mentors and partners is the backbone of the Scaleup Program. During their time in the program, participating startups will have access to a wide range of connections across the Australian energy market, setting the stage for ongoing opportunities to commercialise and deploy their technologies.</p>',
      '<p class="justified">EnergyLab CEO Megan Fisher said, “It\'s a real privilege to be able to provide the world\'s leading energy startups the platform they need to grow and scale their businesses in Australia and New Zealand. In our third Scaleup Program we are thrilled to have startups joining us from all over the globe. Since its inception in 2020, we’ve been able to successfully bring startup and corporate partnerships to life and drive EnergyLab’s mission to decarbonise the economy by supporting climate tech startups. This year, we are beyond thrilled to have one of our own alumni, Amber Electric, who graduated from our 2018 Accelerator as a partner. With Amber having moved from an exciting startup to now being one of the most innovative retailers in Australia joining our partner group, we now have partners representing a much wider portion of the energy value chain.”</p>',
      '<p class="justified">From the 2021 Scaleup Program cohort, V-Labs Managing Director Diemer Cohen Stuart said, “For V-Labs, EnergyLab’s Scaleup Program was a great and efficient way to get a first foothold in the Australian energy market. Thanks to the program, we managed to land a great customer, which has allowed us to expand our activities in this fantastic country further!”</p>',
      '<p class="justified">Fellow graduate Daniel Danilatos, Founder and CEO of Neara said, “We found the agenda to be extremely well designed, structured & executed. The entire EnergyLab team has a keen appreciation for what is required to bridge the commercial engagement gap between start-ups and energy utilities. This ensured the program was pragmatic & focused on enabling the greatest likelihood of tangible opportunities to result. It was also fantastic getting to know other global companies navigating similar trajectories and customer engagement.”</p>',
      '<p class="justified">The 11 companies in the 2022 Scaleup Program cohort are:</p>',
      `<div class="simple-list">
        <div class="url"><a class="dark-green" href="http://www.amberkinetics.com/" target="_blank">Amber Kinetics</a>(Australia)</div><div>The Next Frontier in Energy Storage</div>
        <div class="url"><a class="dark-green" href="http://biapower.io" target="_blank">Bia</a>(Spain)</div><div>Smart EV charging software featuring high-performing forecasting algorithms and a powerful optimization engine to intelligently manage, predict, and optimize EV charging.</div>
        <div class="url"><a class="dark-green" href="https://www.encoord.com/" target="_blank">encoord</a>(US)</div><div>Enabling energy stakeholders to plan the energy transition.</div>
        <div class="url"><a class="dark-green" href="https://www.enging.pt/" target="_blank">Enging</a>(Portugal)</div><div>Enging is an innovative provider of asset condition monitoring solutions for mission-critical industrial assets.</div>
        <div class="url"><a class="dark-green" href="https://ev.energy/" target="_blank">ev.energy</a>(UK)</div><div>Cheaper, greener, simpler electric vehicle charging, for everyone.</div>
        <div class="url"><a class="dark-green" href="http://goodcar.co/" target="_blank">The Good Car Company</a>(Australia)</div><div>The Good Car Company is an affordable electric vehicle marketplace with a mission to decarbonise transport and energy.</div>
        <div class="url"><a class="dark-green" href="http://www.ionate.energy/" target="_blank">IONATE</a>(UK)</div><div>Transforming power through fundamental control innovation.</div>
        <div class="url"><a class="dark-green" href="http://iote2e.com/" target="_blank">IOTE2E</a>(US)</div><div>Purpose-Built, Reliable, and Robust IoT Data Links and Service Platforms for Smart Energy and Smart Cities.</div>
        <div class="url"><a class="dark-green" href="https://www.pixforce.com.br/" target="_blank">Pix Force</a>(Brazil)</div><div>Pix force develops solutions based on Computer Vision and Artificial Intelligence to provide valuable information to our clients through the automatic analysis and interpretation of collected images and videos.</div>
        <div class="url"><a class="dark-green" href="https://www.village.energy/" target="_blank">Village Energy</a>(Australia)</div><div>Village Energy connects the billions of household energy assets to the energy system in a way that works.</div>
        <div class="url"><a class="dark-green" href="http://voltaware.com/" target="_blank">Voltaware</a>(UK)</div><div>White-label Energy Insight Platform to drive engagement and unlock new revenues.</div>
      </div>`,
      '<p>The 2022 Scaleup Program is underway now, and will culminate in a public showcase event on September 14th. <a class="dark-green" href="https://www.eventbrite.com.au/e/energy-scaleup-program-showcase-tickets-396308949737" target="_blank">Register your interest for this event</a> to see the startup cohort and partners showcase the next evolution of decarbonisation in the energy sector.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-30-cover.png',
    date: '08 June 2022',
    link: '/blog/2022-06-08/practical-guide-to-save-energy',
    title: 'Practical Guide to save energy',
    preview:
      "We have compiled 10 tips and insights to provide customers with full transparency on their energy use.",
    text: [
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-30.png" alt="Practical Guide for Energy Crisis" /></div>',
      '<p class="justified">Since the energy price cap increased by 54%, UK energy bills are seeing rapid growth - leaving costumers feeling powerless.</p>',
      '<p class="justified">In line with our mission to provide customers with full transparency on their energy use, we are sharing actionable tips for reducing energy costs.</p>',
      '<h3 class="title bold no-space dark-green">Taking immediate action</h3>',
      '<span class="darker">1. Standby</span>',
      '<div class="blog-post-30"><p class="justified">Appliances not in use still consume electric power, until they are fully switched off at the plug. Gaming stations, TVs, chargers left in standby can account for one third of your total consumption.</p><div class="energy-tip-container"><div class="energy-tip"><span class="tip-label">Tip</span>Switch off appliances at the plug<br/><span class="tip-label">Savings</span>up to 50 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/energy-tips-standby.png" width="50px" alt="Energy Tips - Standby" /></div></div></div>',
      '<span class="darker">2. Tumble Dryer</span>',
      '<div class="blog-post-30"><p class="justified">If the space is available to you, drying your clothes by air instead of using the Tumble Dryer is a solid way  to  save  money. Switching  to a  more  efficient heat  pump  dryer  will  also  contribute  to  reducing costs.</p><div class="energy-tip-container"><div class="energy-tip"><span class="tip-label">Tip</span>Dry your clothes indoors<br/><span class="tip-label">Savings</span>up to 180 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/energy-tips-tumble-dryer.png" width="50px" alt="Energy Tips - Tumble Dryer" /></div></div></div>',
      '<span class="darker">3. Kettle</span>',
      '<div class="blog-post-30"><p class="justified">Running the kettle with just enough for one cup of tea instead of filling the water to the top can be a real money saver. Storing hot water in a thermos for later usage can also be a good way to save costs.</p><div class="energy-tip-container"><div class="energy-tip"><span class="tip-label">Tip</span>Don’t fill the kettle to the top<br/><span class="tip-label">Savings</span>up to 70 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/energy-tips-kettle.png" width="50px" alt="Energy Tips - Kettle" /></div></div></div>',
      '<span class="darker">4. Washing Machine</span>',
      '<div class="blog-post-30"><p class="justified">Using your washing machine on low temperature cycles can help cut down your energy use. However, you will need to run periodic maintenance washes to avoid faster deterioration.</p><div class="energy-tip-container"><div class="energy-tip"><span class="tip-label">Tip</span>Run cycles on colder settings<br/><span class="tip-label">Savings</span>up to 15 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/energy-tips-washing-machine.png" width="50px" alt="Energy Tips - Washing Machine" /></div></div></div>',
      '<span class="darker">5. Install a heat pump</span>',
      '<div class="blog-post-30"><p class="justified">For those who can afford the significant upfront costs, investing in a heat pump will greatly reduce electricity usage for heating - up to 50% in comparison to electrical furnaces and heaters.</p><div class="energy-tip-container"><div class="energy-tip"><span class="tip-label">Tip</span>Switch to a heat pump<br/><span class="tip-label">Savings</span>up to 1,000 GBP / year</div><div class="energy-tip-img"><img src="/img/technology/energy-tips-heat-pump.png" width="50px" alt="Energy Tips - Install a Heat Pump" /></div></div></div>',
      '<h3 class="title bold no-space dark-green">Advanced insights to save energy costs</h3>',
      '<div class="blog-post-30"><p class="justified no-space">Voltaware’s Energy Insight platform has been built in alignment with your needs. Consider switching to one of our <a class="energy-tip-link" href="https://voltaware.com/our-offer-to-utilities" target="_blank">Utility Partners</a> to receive personalized, actionable energy and save energy & cost.</p></div>',
      '<span class="darker">1. Smart Reports</span>',
      '<div class="blog-post-30"><p class="justified smaller no-space">Voltaware’s smart report provides consumers with <span class="green-highlight">monthly appliance breakdown</span>, personalized tips & recommendations, peer to peer comparison and carbon footprint - helping you to become more energy efficient.</p><img class="energy-tip-phone" src="/img/technology/energy-tips-phone.png" alt="Energy Tips - Mobile Solution" /></div>',
      '<span class="darker">2. Peak tariff alerts</span>',
      '<div class="blog-post-30"><p class="justified smaller no-space">If you are on a <span class="green-highlight">dynamic tariff with peak prices</span>, Voltaware will alert you when appliances like Washing Machine are being used during expensive times. You can then use it later once prices drop down.</p></div>',
      '<div class="blog-post-30"><img class="energy-tip-phone-second" src="/img/technology/energy-tips-phone.png" alt="Energy Tips - Mobile Solution" /></div>',
      '<span class="darker">3. Appliance Monitoring</span>',
      '<div class="blog-post-30"><p class="justified smaller no-space">Voltaware monitors critical appliances like the fridge to detect whether they are becoming inefficient. Voltaware can also suggest more efficient replacement appliances to <span class="green-highlight">reduce costs in the long-term</span>.</p></div>',
      '<span class="darker">4. Leaving Home alert</span>',
      '<p class="justified">Every time you leave your home, Voltaware checks what appliances are currently running. If any of these are critical - like <span class="green-highlight">the iron or the oven</span> - we will notify you immediately, so you can come back home and turn it off.</p>',
      '<span class="darker">5. Peer to Peer</span>',
      '<p class="justified">Voltaware shows how your energy use compares to our <span class="green-highlight">peer grouping system</span> which allows you to understand the level of energy efficiency across your appliances.</p>'
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-29.png',
    date: '03 May 2022',
    link: '/blog/2022-05-03/voltaware-&-morgan-sindall-property-services-case-study',
    title: 'Voltaware & Morgan Sindall Property Services Case study',
    preview:
      'Voltaware partners with Morgan Sindall Property Services (MSPS) and goldeni to identify opportunities to reduce energy consumption and costs for social housing tenants.',
    text: [
      '<p class="justified">British energy regulator Ofgem raised its price cap to an unprecedented 54% last April 1st, leading to a significant increase of around £700 in yearly energy bills. UK households in fuel poverty may double to 5 million - making technologies that offer customers full transparency over their energy use, essential.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-29.png" alt="Elderly Monitoring" style="width: 600px" /></div>',
      '<p class="justified">This is why MSPS and goldeni are partnering with Voltaware to provide social housing tenants with the tools to identify  energy wastage  and reduce their electricity bills.</p>',
      '<p class="justified">Installed within the home, Voltaware’s algorithms split the mains incoming electricity bill down to individual appliances - allowing customers to identify what home appliances are costing them money and when. Once social housing tenants become aware of which appliances are costing them the most money, they can make changes to the way they operate these appliance and instantly start saving money.</p>',
      '<p class="justified">According to the Energy Saving Trust’s research, between 9-16% of the electricity consumed in homes is used to power appliances when they are left on standby. That’s an astonishing £85/annum for an average household.</p>',
      '<p class="justified">After installing Voltaware sensors across MSPS’s residential homes, one consumer found that around 23% of their total consumption was from appliances left in standby - from items such as TVs and phone chargers to laptops and children’s gaming stations.</p>',
      '<p class="justified">Demonstrating appliance-level insights to social housing tenants can help them make more informed decisions about the activities in their homes. goldeni then uses this information to provide tips and recommendations via its Home Health app to encourage a change in tenant behaviour, prompting them to switch appliances off at the wall for example, and become more energy efficient.</p>',
      '<p class="justified">In an effort to fight against fuel poverty and get to NetZero, MSPS and goldeni are gearing up to deploy Voltaware sensors across a number of UK social housing properties.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-28.png',
    date: '14 Feb 2022',
    link: '/blog/2022-02-14/elderly-monitoring-using-smart-meter-data',
    title: 'Elderly Monitoring using Smart Meter data',
    preview:
      'Worried about an elderly parent living alone? VoltaCare is here to help.',
    text: [
      '<p class="justified">We’ve all jumped between endless meetings, the work commute and school pickup. By the time you’re in bed, after a day of balancing all your commitments, you realise you have forgotten to call your parents…</p>',
      '<p class="justified">The global pandemic has aggravated the social isolation of the elderly and vulnerable living alone, and this will become a growing problem as societies around the world age.</p>',
      '<p class="justified">This is why we’ve introduced VoltaCare - a state-of-the-art, scalable elderly monitoring solution using smart meter data to provide peace of mind to anyone worried about their elderly relatives. By learning their daily routines, VoltaCare is able to detect inactivity. It is tailored to the lifestyle of those you care about, in the most unobtrusive and privacy-friendly way possible.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-28.png" alt="Elderly Monitoring" /></div>',
      '<p class="justified"><strong>I. VoltaCare is the future of Elderly Monitoring</strong></p>',
      "<p class='justified'>Elderly Monitoring solutions today are targeted to people with critical conditions, who need real-time and very close monitoring - meaning they are not relevant for most people. No Elderly Monitoring product has been designed for family members concerned about their healthy parents - who often live in a different place. There is currently no solution scalable to the mass market that everyone can relate to. There is currently no solution available in the market whichmonitors elderly resident's lifestyle, and is also scalable to the mass market that everyone can relate to.</p>",
      "<p class='justified'>Elderly monitoring tech available today such as wearable devices, only record personal movements without collecting any information about the resident's activities in the house. Wearables are often uncomfortable and cumbersome, and are therefore left unused. Other tech include environmental devices such as home cameras and presence sensors, which feel obtrusive and end up being very expensive.</p>",
      '<p class="justified"><strong>II. Introducing VoltaCare - unique Elderly Monitoring</strong></p>',
      "<p class='justified'>VoltaCare is a state-of-the-art, unobtrusive elderly monitoring solution using smart meter data to identify the use of electrical appliances. Using thousands of appliance data collected over the last 5 years, we’ve built an AI algorithm that automatically identifies appliances used at home - in real-time. VoltaCare learns the elderly's daily routines and is able to detect inactivity.</p>",
      "<p class='justified'>VoltaCare also alerts you about the elderly's level of activity by generating two types of behavioural alerts - Caution and Critical. Caution is generated when a person remains inactive for more than 12 hours, and Critical when no activity is recorded for more than 18 hours. VoltaCare also assigns a score to each activity performed based on their habits.</p>",
      "<p class='justified'>New features are in development, such as unusual duration of an appliance’s usage, night time activities and limited appliance usage for a long time. For instance, if a person normally uses the washing machine weekly but hasn’t used it for more than two weeks.</p>",
      '<p class="justified"><strong>III. Paving the way for innovation in the HealthCare market</strong></p>',
      "<p class='justified'>Thanks to its cost effectiveness, 5 minute setup and unobtrusiveness, VoltaCare can easily integrate with existing smart home offerings - making it easy for Telecoms / Insurance / Utilities to offer this to their customer base.</p>",
      "<p class='justified'>VoltaCare is fully white-labelled and integrates directly into companies’ customer-facing applications - so our partners can offer a unified experience while unlocking new revenue streams.</p>",
      "<p class='justified'>Health care and social care agencies can use VoltaCare as an effective, low cost monitoring and alert system to help reduce their cost and make care more accessible.</p>",
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-27-cover.png',
    date: '15 Nov 2021',
    link: '/blog/2021-11-15/voltaware-at-enlit-2021',
    title: 'Voltaware at ENLIT 2021',
    preview:
      'Voltaware is excited to present its new products at ENLIT 2021 from 30 November until 2 December 2021 in Milan - join us at stand 12.D94!',
    text: [
      '<p class="justified">Now, more than ever, Voltaware is excited to share with you our contribution to the utility market and role in bringing innovation to the energy transition. Following a two-year break from the fair, we are delighted to showcase how our energy monitoring technology and products have developed.</p>',
      '<p class="justified">We’ve cemented our role working the world’s most dynamic utilities and energy disruptors, and can’t wait to show you how our proprietary energy monitoring technology can help redefine the utility, elderly care and smart meter markets.</p>',
      '<p class="justified"><strong>Come visit us at stand 12.D94</strong> at ENLIT Milan, we look forward to meeting partners old and new.</p>',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-27.png" alt="Voltaware at ENLIT 2021" /></div>',
      '<p class="justified">In only a few weeks, you will be able to see for yourself our latest developments:</p>',
      '<p class="justified"><strong>Live disaggregation</strong>: Our brand new algorithm will allow customers to understand their appliance use in real-time, shaping more informed decisions around energy savings - especially for customers on dynamic tariffs.</p>',
      '<p class="justified"><strong>VoltaCare</strong>: We’ve built an unobtrusive, privacy friendly behavioural monitoring solution for the single elderly using electrical appliance usage. VoltaCare detects various behavioural anomalies, such as inactivity, sleeplessness, forgetfulness - to alert family members to unusual patterns.</p>',
      '<p class="justified">It will also be a great moment to highlight some of our successful large-scale deployments with some of Europe’s most innovative utilities, such as Utilita, Octopus Energy, Engie, Onda Piu and many others.</p>',
      '<p class="justified">Voltaware will be exhibiting at Stand 12.94. Please get in touch with us if you would like to receive a free invitation to the event.</p>',
      '<p class="justified">We look forward to seeing you there!</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-26-cover.jpg',
    date: '13 Sep 2021',
    link: '/blog/2021-09-13/meet-the-team-maria',
    title: 'Meet the team | Maria',
    preview:
      'You may be familiar with our technology but do you know the brains behind it? Every now and then, we’d like to bring the Voltaware team closer to you and what makes who we are.',
    text: [
      'Meet Maria - our Data Scientist and a key member of Voltaware’s Data Science Team. Maria joined Voltaware more than 2 years ago and contributed to the successful delivery of our main projects with utilities. Maria is now leading on our real-time disaggregation algorithm, which will provide a new range of next level insights for our utility customers. She shares her journey at Voltaware with us.',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-26.jpg" alt="Meet the team | Maria" /></div>',
      '<h3 class="question">How did you take an interest in Data Science?</h3>',
      '<p class="justified">Mainly by reading articles about Artificial Intelligence in my free time. I got genuinely excited about the potential of Artificial Intelligence, its incredible benefits and the complex problems it can solve.</p>',
      '<p class="justified">Besides, data and Artificial Intelligence became ubiquitous in our daily lives, sometimes without us noticing it and involving serious ethical issues when inappropriately used.</p>',
      '<p class="justified">I was eager to learn more. So much that I decided to change my career path altogether, from Banking and Financial Markets to Data Science. This eventually turned out to be a great choice for me.</p>',
      '<h3 class="question">How did you hear about Voltaware and what motivated you to join the team?</h3>',
      '<p class="justified">After completing a MSc course in Data Analytics, in September 2019 I started searching for a job and came across one of Voltaware’s job advertisements with an open position in Milan’s office. As I gathered more information about the company and the energy disaggregation field, I felt that Voltaware’s objectives were aligned with mine.</p>',
      '<p class="justified">First of all, I am interested in environmental sustainability and I was, back then, looking for a company that could make a difference in this respect.</p>',
      '<p class="justified">Secondly, I was looking for a position in a company with a strong focus on Data Science, that would give me the opportunity to apply the skills and knowledge acquired during the Master course, but also to grow personally and professionally and acquire new skills.</p>',
      '<p class="justified">I thought that Voltaware was the right place for both these goals.</p>',
      '<h3 class="question">What is your role at Voltaware and how does your typical day look like?</h3>',
      '<p class="justified">I am Data Scientist and my daily tasks can vary depending on the ongoing projects. At Voltaware we continuously develop improvements of the existing energy disaggregation algorithms and new product features, therefore no day is the same as the previous one. For the last few months my focus has been on a project that will radically change our approach to energy disaggregation with the ultimate goal of having the energy disaggregation results in almost real time. My daily routine usually involves data analysis tasks, problem solving and plenty of coding in Python, as well as coordination with the rest of the team to align on the progress and objectives.</p>',
      '<h3 class="question">What is your role at Voltaware and what skills - technical & soft - have you learned since joining the team?</h3>',
      '<p class="justified">I learned countless skills since joining Voltaware, given the variety of projects and tasks I worked on. I definitely took my (Python) coding skills to the next level, as I spend most of my time coding.</p>',
      '<p class="justified">I improved my technical knowledge about Data Science techniques, learning from the rest of the team. I enhanced my communication skills, having to regularly present my work and findings to technical and non-technical people, both from the Voltaware team and from utility clients.</p>',
      '<p class="justified">I learned to be more creative and improved my problem-solving skills, having to solve complex problems with the best possible approach. I also acquired a significant wealth of knowledge about electricity data and smart metering.</p>',
      '<h3 class="question">Did the global pandemic affect your work at Voltaware or the Team?</h3>',
      '<p class="justified">When the COVID-19 pandemic started, Voltaware promptly asked all the office-based employees to switch to smart working, in complete safety. The whole team responded very positively to this new way of working, ultimately contributing as always to the achievement of the company’s objectives.</p>',
      '<p class="justified">Personally, the pandemic hasn’t affected me at all, I continued to do my usual work, only from home and with the valuable support and collaboration of my colleagues, always reachable through video calls.</p>',
      '<h3 class="question">Can you tell us about your personal and professional goals this year?</h3>',
      '<p class="justified">My personal goals are to attend a photography course, participate in a photography contest and start learning Spanish.</p>',
      '<p class="justified">My professional goals are to complete, together with the rest of the team, the real-time energy disaggregation project and subsequently develop new features with interesting insights for our clients.</p>',
      '<h3 class="question">What do you like to do outside work?</h3>',
      '<p class="justified">My hobbies outside work are traveling, trekking and photography.</p>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-25.png',
    date: '14 Jul 2021',
    link: '/blog/2021-07-14/voltaware-clean-equity-monaco-2021',
    title: 'Voltaware to Present at CleanEquity® Monaco 2021',
    preview:
      'Voltaware will present at CleanEquity® Monaco 2021 on 22nd & 23rd July at the Fairmont Monte Carlo.',
    description:
      '<strong>Voltaware</strong> will present at CleanEquity® Monaco 2021 on 22nd & 23rd July at the Fairmont Monte Carlo.',
    text: [
      '<div><img src="/img/del/blog-img-25b.png" alt="Voltaware and Octopus Offer" /></div>',
      'CleanEquity® is an annual invitation-only event hosted by Innovator Capital, the London based specialist investment bank. The 30 best-in-class sustainable technologies must meet high standards set by the selection committee. The conference provides an intimate and collegiate setting for inventors and entrepreneurs to share their stories with delegates - key decision makers looking to assist them with reaching their commercial and strategic goals.',
      'Other partners and sponsors include Prince Albert II of Monaco’s Foundation, Cision, Covington & Burling, Cranfield University, the Monaco Economic Board and Taronis Fuels.',
      '<strong>Voltaware</strong> has been identified by an expert panel as one of the world’s most innovative sustainable technology companies and has been selected to present to selected sovereign, corporate, family and professional investors & acquirers, policy makers, end users and international trade media.',
      '<strong>Voltaware</strong>’s AI driven products offer a real opportunity for energy companies to differentiate and unlock new revenue streams in a way that’s not been possible before. This event will be the opportunity to showcase our exciting product & commercial updates, while meeting relevant industry experts and investors.',
      '<strong>About Innovator Capital Limited</strong>',
      'Innovator Capital (ICL), established in 2003, is a London based specialist investment bank focusing on health and sustainable technology companies; advising on corporate finance, mergers and acquisitions, IP, business & corporate development. ICL’s mission is to assist IP rich private & public companies with finding the right investors, acquirers, customers, licensees and commercial partners.',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-24.png',
    date: '01 Jul 2021',
    link: '/blog/2021-07-01/voltaware-octopus-insights',
    title:
      'Voltaware and Octopus Energy partner to bring advanced energy insights to their customers',
    preview:
      'Voltaware’s cutting-edge energy insights is now available for Octopus smart meter customers as part of their Works with Octopus programme. To celebrate we’re offering an exclusive 25% off promotion for Octopus customers.',
    description:
      'Voltaware’s cutting-edge energy insights is now available for Octopus smart meter customers as part of their <a href="https://octopus.energy/works-with-octopus" target="_blank" rel=”noopener noreferrer”>Works with Octopus</a> programme. To celebrate we’re offering an exclusive 25% off promotion for Octopus customers.',
    text: [
      'Octopus users can now buy a Voltaware sensor and connect it to their Octopus account to get appliance-level insights and advice to reduce their bills.  If they are on smart tariff like Agile Octopus or Octopus Go, they can extract even more value by understanding and adapting their energy use around peak times.',
      '<div style="display: flex; justify-content: center;"><img src="/img/del/blog-img-24.png" alt="Voltaware and Octopus Offer" /></div>',
      'Octopus users can access these insights through the Smart Energy dashboard, a web portal where they can visualise their half-hourly energy usage, carbon footprint and smart tariff rates.',
      '<div style="display: flex; justify-content: center;"><img src="/img/del/blog-img-24b.png" alt="Smart Energy Dashboard" /></div>',
      'The partnership allows Octopus customers to get a Voltaware sensor at the one-off price of just £149.99, <strong>for a limited time only</strong>. ',
      'To enable Voltaware on Octopus, users need to:',
      '<ol class="with-margin-list" style="margin-left: 56px"><li>Order Voltaware <a class="dark-green" href="https://octopus.voltaware.com" target="_blank" rel=”noopener noreferrer”>https://octopus.voltaware.com</a></li><li>Install Voltaware and sign up</li><li>Create an Octopus Smart Energy account <a class="dark-green" href="https://smart-energy.octopushome.net" target="_blank" rel=”noopener noreferrer”>https://smart-energy.octopushome.net</a></li><li>Follow the instructions to link their Voltaware account</li></ol>',
      'Additionally, Octopus users will get the full suite of Voltaware Applications and Home insights, which includes:',
      '<ul class="with-margin-list" style="list-style-type: &quot;→ &quot;;" → ";"=""><li>Real-time energy monitoring</li><li>P2P comparisons</li><li>Appliance monitoring</li><li>Safety & Security alerts</li><li>Personalised tips & recommendations</li><li>Monthly energy reports</li><li>New features and improvements on the go</li></ul>',
      'These will help users save energy, reduce bills, improve their security and comfort, minimise carbon footprint and much more…',
      'Phil Steele, Octopus Energy, Head of R&D, said that ‘<em>Did you use the dishwasher at 6.00pm? Our Agile Octopus customers are acutely aware of the higher rate between 4.00pm and 7.00pm so being able to get a breakdown of what the culprit things around the home are thanks to the amazing Voltaware tech is really powerful and engaging</em>’.',
      'Rishabh Jaipuria, Voltaware, COO, said that ‘<em>This partnership showcases how Voltaware’s energy insights platform allows Utilities to enhance their consumer proposition - aligning themselves with consumer goals, delivering value and speeding up energy transition. The integration with Octopus has been seamless, and for the first time we will be offering our sensors directly to the end user</em>’.',
      '25% off Voltaware sensors for Octopus customers are available for a limited time only, <a class="dark-green" href="https://octopus.voltaware.com" target="_blank" rel=”noopener noreferrer”>https://octopus.voltaware.com</a> to find out more.',
      'Works with Octopus → <a class="dark-green" href="https://octopus.energy/works-with-octopus" target="_blank" rel=”noopener noreferrer”>https://octopus.energy/works-with-octopus</a>',
      'About Agile Octopus tariff → <a class="dark-green" href="https://octopus.energy/agile" target="_blank" rel=”noopener noreferrer”>https://octopus.energy/agile</a>',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-23.jpg',
    date: '15 Feb 2021',
    link: '/blog/2021-02-15/meet-the-team-antoine',
    title: 'Meet the team | Antoine',
    preview:
      'You may be familiar with our technology but do you know the brains behind it? Every now and then, we’d like to bring the Voltaware team closer to you and what makes who we are.',
    text: [
      'Meet Antoine - our Head of Data Science Team and a pivotal part of Voltaware. Antoine joined Voltaware 4 years ago and has built our disaggregation algorithm, which is at the core of IRIS and all our actionable energy insights. His role has been increasingly more important as he now leads the Data Science Team. He shares his journey with us.',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-23.jpg" alt="Meet the team | Antoine" /></div>',
      '<h3>What is your background?</h3>',
      '<strong>Antoine:</strong> My academic background is in pure mathematics. In 2014, I completed a PhD at the University of Lille (in the north of France), in the field of "geometric group theory”.  After spending two more years doing mathematical research in academia, I got increasingly interested in AI-applications as I found that my mathematical skills could apply not only to abstract, but also to real business problems.',
      'I then started a career transition towards Data Science and applied for jobs in the private sector. For that, London was (and still is) the place in Europe with the most opportunities. At the end of 2016, I found an internship in a London-based company specialising in data analytics applied to marketing. I moved to London, did my internship, applied for jobs and was contacted by Voltaware at the beginning of 2017. This is how my Voltaware journey started.',
      'Outside work, I like to read (novels and poetry) and run along the Thames river or around Greenwich park. Although now, most of my spare time is dedicated to my baby girl born in December 2020!',
      '<h3>What is your role at Voltaware?</h3>',
      '<strong>Antoine:</strong> In one sentence, my role is to head the Data Science team to ensure we are building the best AI-algorithms that will be the base for our actionable insights. My role is to guarantee that the work of our team is aligned with business, but also in turn that business expectations are aligned with the Data Science team.',
      'On the one side, we have 8 people in the team - all of them carrying different backgrounds and levels of experience. I am speaking with each of them on a daily basis to make sure they know what tasks they need to prioritise, but also how they need to accomplish these and why they are important.',
      'On the other side we have a product and a business team that is using our AI-algorithms to develop features and use cases driven from it, which are then available on our IRIS platform for utilities. We have many European utility partners in Europe and are consistently expanding to new markets, which means that the Data Science team needs to constantly refine and improve our algorithms.',
      'Finally, my role is to supervise the implementation of Data Science products by the team and to draw the roadmap of development with the product team.',
      '<h3>How would you describe your journey since you’ve joined Voltaware?</h3>',
      '<strong>Antoine:</strong> Voltaware was my first permanent experience in the private sector. At the same time, I was the first data scientist to join the company. So my first months inside the company were a learning process both for me and the existing team.',
      'Back then, I was mainly working hands-on the code: implementing and testing algorithms for disaggregation. Quickly though, data analysts and scientists joined the team and my role continuously evolved since then (and the company as well) growing until where we are now.',
      '<h3>Why is the Data Science Team so important to Voltaware?</h3>',
      'Voltaware was founded by people that believe in the power of data for good. With the climate crisis being every day more concerning, we want to use the power of data to help people change the way they use energy and help them align closer to their goals.',
      'We have proven that energy disaggregation allows customers to get more transparent energy bills, helping them to save energy and money - which is very important to them. Disaggregation is also at the base of many more personalised energy insights, allowing us to engage customers further.',
      'As we grow and rapidly expand, we are collecting large volumes of insights which will help us enhance our AI-powered algorithms and bring exciting features to our customers. Data and our team are key to Voltaware’s global ambition.',
      '<h3>Can you tell us about your personal and professional goals this year?</h3>',
      '<strong>Antoine:</strong> My personal goals this year are to become a great father, to run a semi-marathon (if allowed by Covid) and to read an English classical novel.',
      'My professional goal - speaking on the company’s behalf here - is to connect IRIS to 1m new homes.',
      '<h3>What do you like at Voltaware?</h3>',
      'Voltaware is a company where every employee is trusted and encouraged to actively contribute to collective efforts even outside of their strict domain of expertise. This is particularly important to support the development of young and inexperienced people and those joining our teams without specific experience in our field.',
      'As I had no experience in the private sector, giving me crucial responsibilities at the very beginning was a great testimony of Voltaware management’s trust in me. I now try to introduce a similar culture of trust and courage across all DS newcomers.',
      'Watch our space for exciting updates!',
    ],
    tags: [],
    related: [],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-21.png',
    date: '05 Jan 2021',
    link: '/blog/2021-01-05/powering-disruption-with-telefonica',
    title: 'Powering disruption with Telefónica',
    preview:
      'We are delighted to be part of the Telefonica Activation Programme which is #PoweringDisruption.',
    text: [
      'The programme will support the expansion of our IoT offerings & AI-driven insights and help discover new opportunities with Telefonica across the world.',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-22.png" alt="Powering disruption with Telefónica" /></div>',
      'The Telefonica Activation Programme offers exclusive and free access to new technologies thanks to a range of APIs. Under the support of Telefonica, Voltaware will have access to different technological platforms:',
      '<ul class="with-margin-list" style="list-style-type: &quot;→ &quot;;" → ";"=""><li><strong>IoT:</strong> We have received an IoT Toolkit with Telefonica SIMs, along with 6 months free global connectivity to test and validate NB-IoT and LTE-M Voltaware sensors. These new sensors could be used in remote commercial / industrial sites where Wi-Fi is not accessible or to constantly monitor elderly and vulnerable individuals.</li><li><strong>AI:</strong> Telefonica’s LUCA Suite for AI will help us integrate Machine Learning capabilities in an easy and intuitive way. Our data science team will be using LUCA to enhance its machine learning models and develop new energy & home insights.</li></ul>',
      'In addition, Voltaware has become part of the Wayra ecosystem to identify new use cases within the telecom sector, network with other entrepreneurs and prepare new pilots.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-13-cover.jpg',
        link: '/blog/2020-06-15/voltaware-secures-role-within-energia-positiva',
        title: 'Voltaware secures role within Energia Positiva',
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-19.png',
    date: '23 Nov 2020',
    link: '/blog/2020-11-23/paving-the-way-for-spain-most-groundbreaking-utility',
    title: 'Paving the way for Spain’s most groundbreaking utility',
    preview:
      'Voltaware is running a pilot with Capital Energy using IRIS - Voltaware’s new data intelligence platform for utilities - to reinvent its value proposition.',
    text: [
      'Over fifty households have installed Voltaware sensors and the Volta mobile app to unlock AI-driven energy insights. Capital Energy will be integrating with IRIS data intelligence platform, which allows utilities to build disruptive products.',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-20.png" alt="Voltaware and Capital Energy" /></div>',
      'IRIS collects data from either smart meters or Voltaware sensors and unlocks a range of benefits for both utilities and their consumers:',
      '<ul class="with-margin-list"><li><strong>AI insights that address consumer needs:</strong> Energy disaggregation, p2p, tips & recommendations, appliance monitoring, safety & security alerts, elderly monitoring and many other…</li><li><strong>Tools to build better products:</strong> Build differentiated products to increase customer retention, CRM to manage customer interactions better, BI analytics portal to aggregate customer insights, unlock new revenue opportunities using data, etc…</li></ul>',
      "Voltaware is working closely with Capital Energy's venture fund ‘Quantum’, which is using 20 million euros to promote  energy efficiency in homes and sustainable behaviours within the company itself.",
      'We are excited to work with Capital Energy and look forward to advancing the energy transition in Spain and beyond!',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-13-cover.jpg',
        link: '/blog/2020-06-15/voltaware-secures-role-within-energia-positiva',
        title: 'Voltaware secures role within Energia Positiva',
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-17.jpg',
    date: '28 Aug 2020',
    link: '/blog/2020-08-28/voltaware-and-regalgrid-build-revolutionary-energy-communities',
    title: 'Voltaware and Regalgrid to build revolutionary energy communities',
    preview:
      'Voltaware and Regalgrid have agreed a 3 year partnership to combine Voltaware’s cutting-edge electricity sensors and advanced energy insights with Regalgrid’s innovative digital platform serving 50k homes.',
    text: [
      'Regalgrid is building energy communities balancing production, storage and consumption among their users with the ambition to connect growing networks of people promoting self-sufficiency and Self-Consumption of renewable clean energy.',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-18.jpg" alt="Voltaware and Regalgrid partnership" /></div>',
      'For the first time, Voltaware will be bringing its hardware and software services to help Regalgrid develop powerful energy insights across its communities: Voltaware will be producing sensors that will send ultra-high resolution energy data to Regalgrid cloud platform and algorithms.',
      'A proportion of these sensors will benefit from Voltaware’s advanced energy insights, helping Regalgrid users:',
      '<ul class="with-margin-list"><li>Understand their electricity bill down to the appliance level</li><li>Track their appliance use in real-time</li><li>Reduce carbon footprint</li><li>Improve their comfort at home</li><li>Enhance safety & security</li></ul>',
      'Over the next 3 years, more than 50k homes across Italy and Australia will benefit from these revolutionary services.',
      'Davide Spotti- CEO, Regalgrid Europe said that <span class="quotation">“Thanks to the Regalgrid® technology, not only prosumers, but also the end users and simple energy consumers will finally become an important part of the energy sharing system. No matter if living in isolated households either in multi-flat buildings, it is our intention to help citizens to benefit together from a system that reduces energy waste, maximizes usage of local generated renewable energy and has social and economic positive impacts. Regalgrid® technology is providing sound advantages orchestrating a variety of different brands of different energy assets like solar panels and inverters, energy storage devices, heat pumps and electric vehicles chargers.”</span>',
      'Sergey Ogorodnov- CEO, Voltaware said that <span class="quotation">“This partnership will revolutionise the energy industry, bring energy communities closer together and promote microgrid solutions to develop independent choices for residential and SMEs customers. For Voltaware, it confirms once more why our sensors are the best in the market and the only ones of their kind. Regalgrid users will be able to install their new SNOCU in only 5 minutes, and benefit from Regalgrid’s advanced digital energy platform. Regalgrid users willing to take command of their energy use further will be able to upgrade to Voltaware’s energy and home insights. Regalgrid and Voltaware are paving the way for innovation in the energy industry.”</span>',
      'Interested customers should contact Regalgrid to activate their SNOCU (Smart Node Control Unit) and receive powerful energy insights.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-13-cover.jpg',
        link: '/blog/2020-06-15/voltaware-secures-role-within-energia-positiva',
        title: 'Voltaware secures role within Energia Positiva',
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-15.jpg',
    date: '27 Jul 2020',
    link: '/blog/2020-07-27/voltaware-receives-the-advancing-low-carbon-2020',
    title: 'Voltaware receives the Advancing Low Carbon 2020',
    preview:
      'We are proud to be accredited under BP’s Advancing Low Carbon programme for a second year in a row.',
    text: [
      'The Advancing Low Carbon (ALC) accreditation programme sponsored by BP and Deloitte highlights companies helping BP, businesses and the world get to net zero. Voltaware is furthering research and technology to advance low carbon and the <span style="font-weight: bold; margin: 0">#EnergyTransition</span>.',
      '<div style="display: flex; justify-content: center;"><img src="/img/del/blog-img-16.jpg" alt="Voltaware Advancing Low Carbon" /></div>',
      'Voltaware’s AI Energy Platform is at the heart of the company’s efforts to bring low-carbon technology into homes and businesses. It enables individuals and businesses to improve visibility of their energy use, understand their carbon footprint and decipher complex energy bills - leading to improved energy efficiency, increased environmental awareness and low carbon homes and businesses.',
      'Only 50 companies within the entire BP group are accredited with the ALC award, and we are excited to be one of them. Looking forward to Advancing Low Carbon 2021 and beyond!',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-13-cover.jpg',
        link: '/blog/2020-06-15/voltaware-secures-role-within-energia-positiva',
        title: 'Voltaware secures role within Energia Positiva',
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-13.jpg',
    date: '15 Jun 2020',
    link: '/blog/2020-06-15/voltaware-secures-role-within-energia-positiva',
    title: 'Voltaware secures role within Energia Positiva',
    preview:
      'Voltaware is supporting the response to COVID-19 in Spain through Energia Positiva.',
    text: [
      'The Energia Positiva initiative has been launched by Enagás, Red Eléctrica, CLH, Iberdrola, BP, EIT InnoEnergy, Acciona, Capital Energy and DISA to alleviate the social and economical impact of Covid-19 on Spain. The platform has made 4M € available to invest in startups able to implement innovative solutions as quickly as possible.',
      '<img src="/img/del/blog-img-14.jpg" alt="Energia Positiva initiative" />',
      'Voltaware is one of 12 startups to have been successfully selected to lead the initiative. Launched on the 7th April 2020, Energia Positiva received 396 proposals in under two weeks. As one of the winners, Voltaware will receive support to develop a project with the potential for rollout. Startups selected needed to demonstrate impact in key areas: social impact against COVID-19, decarbonisation, renewable energy, energy efficiency, storage, sustainable mobility and circular economy.',
      'Voltaware is addressing the issue of social impact by empowering consumers with their utility bills, improving the safety and comfort of their home, and non-intrusively monitoring elderly and vulnerable people through electricity data.',
      'Voltaware is also addressing the issue of decarbonisation and low carbon through helping individuals and businesses to become smarter consumers, understand their carbon footprint , and reduce the lack of transparency in energy bills- all of which leading to greater efficiency, greater environmental awareness and the reduction in greenhouse gas.',
      'We are looking forward to starting rolling out our technology in Spain and supporting the response to COVID-19.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-12.jpg',
    date: '16 Mar 2020',
    link: '/blog/2020-03-16/building-relationships-onda-piu',
    title:
      'Building Relationships Through Customer Innovation: the Onda Più case study',
    preview:
      'Voltaware is pleased to share with you the case study with our partner, Italian utility Onda Piu.',
    text: [
      'Voltaware is pleased to share with you the case study with our partner, Italian utility Onda Piú. The new #EFFI100 bundle, powered by Voltaware, has enhanced engagement between Onda and its customers. This latest offering entails an initial deployment of 2,000 Voltaware sensors that have been relabeled with the Onda Più name, trademarks and logo to customersin Sicily and Sardinia. As part of the campaign, Onda Più has developed its own mobile app built on the existing Voltaware API platform. Voltaware’s advanced data analytics have helped Onda reduce customer churn and overall business costs while maintaining its brand identity.',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-12.jpg" alt="Onda Più sensor" /></div>',
      'Onda customers using EFFI100 benefit from real-time, high-resolution energy consumption data, which is collected by the white-labeled sensors and processed by Voltaware&#39;s proprietary machine learning algorithms that identify home appliances based on their unique electric signatures. This automatic disaggregation of load by device is key to helping customers better manage power use by providing an appliance-level visualization. It is a realistic tool to manage monthly power expenditure.',
      'Appliance alerts are sent when unusual home energy activity or breakdowns are detected. This makes households safer and more secure, and indicates where maintenance may be needed, avoiding major costly repairs down the road. All of this translates into greater consumer awareness of their carbon footprint and helps them change their behaviour accordingly.',
      'Also, white-labeled sensors powered by Voltaware can allow higher valued-added services, such as part of a solution to unobtrusively monitor older family members and loved ones.',
      'Engaging customers and providing them with personalized energy information through a new interactive experience has made this initial launch by Onda Più a success. It has enhanced engagement with consumers, and promoted increased trust and loyalty, thus reducing customer churn and customer acquisition costs. These new services have established Onda Più as Italy’s home-energy innovator.',
      'The initial phase has been so successful that Onda has expanded the rollout of its white-label sensors to an additional 10,000 customers. We look forward to further fruitful cooperation with Onda',
      'Watch the following video to see the EFFI100 at work for Onda Più.',
      '<a target="_blank" rel=”noopener noreferrer” href="https://vimeo.com/329985041">https://vimeo.com/329985041</a>',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-11.jpg',
    date: '03 Mar 2020',
    link: '/blog/2020-03-03/the-results-are-in',
    title: 'The results are in',
    preview:
      'We thank customers who responded to our recent survey regarding Voltaware app usage.',
    text: [
      'We thank customers who responded to our recent survey regarding Voltaware app usage.  Your valuable feedback helps us better understand customers’ needs and identify areas of the mobile app that may need additional tailoring.  Improving product performance to meet consumer expectations is a priority.',
      'Responses came from 16 countries such as South Africa, Lebanon and Russia, while Italy and the UK were the top two countries represented. We are pleased to see a great range of nations making use of our mobile app. We made it for you!',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-11.jpg" alt="The results are in" /></div>',
      '<h3>User Frequency</h3>',
      '93% of users consult their Voltaware App at least three times a month, 63% monitor their energy expenditure with more consistency, twice a week at a minimum, and a true-blue group of 25%, at least once per day. As developers in the energy-tech sector, it is gratifying to see an energy app made for consumers being used consistently in daily lives for positive change.',
      '<h3>Appliance Monitoring Plus</h3>',
      'The podium of real-time appliance monitoring goes to the electric boiler, fridge/freezer, and washing machine respectively.  It’s easy to understand how these top finishers earned their rank as they are typically the most commonly used in any household. Electric boilers warm our homes in the winter and on cold-damp days. Fridges & freezers keep our food fresh longer, and washing machines facilitate how we clean our clothes. Survey timing may also play a role; January responses would differ than those from June for example. Home heating in the winter turns to air-conditioning in the summer.',
      'Concrete and practical information on their electricity usage encourages consumers to reduce their energy costs and alter energy-wasting behaviour. It could also possibly compel them into investing in energy efficiency by upgrading heavy energy-consuming appliances for ones with improved energy ratings. This simple act will start saving you money faster than you think, and you’ll be doing your part to reduce waste across the world.',
      'Real-time energy monitoring is the most popular feature of the Voltaware app as it allows users to track their home activity, even remotely, at any time.  As a result, 95% believe they have improved their electricity consumption or tracked their home activity better. In addition, monthly reports and intelligent alerts will help identify energy wasting behaviour, which will save you money on your monthly bill.',
      '<h3>Customer Feedback</h3>',
      '70% of customers are very satisfied with the algorithm accuracy and the app’s overall ease of use. Energy disaggregation is also greatly appreciated and a major component of customer retention. Who doesn’t want to feel more in control of their bills? We received suggestions for future app features such as improved appliance recognition, adding a carbon footprint tracker and weather-based recommendations. The customer experience remains key in a mobile app.  We strive to enhance our services and pledge to increase customer engagement through specific surveys tailored toward product development.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-10.jpg',
    date: '14 Feb 2020',
    link: '/blog/2020-02-14/innovation-equals-customers',
    title: 'Innovation equals customers',
    preview:
      'In today’s deregulated market, newer and more dynamic energy providers are fighting for market share from traditional suppliers.',
    text: [
      'In today’s deregulated market, newer and more dynamic energy providers are fighting for market share from traditional suppliers. This battle makes energy efficiency, smart home services and innovation key elements to customer acquisition and retention. Whether your company is new or long-established, Voltaware’s home intelligence is ready to drive customer engagement and allow you to monetize through a combination of direct and indirect revenue.  ',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-10.jpg" alt="A smarter future" /></div>',
      'Bring intelligence into the home. Voltaware’s architecture samples high-resolution electricity data from home appliances and delivers a comprehensive energy analysis to your smartphone. This permits customers to manage their energy use and understand everything happening at home. In a world of growing customer expectations, our mobile app monitors your energy consumption in real-time and breaks your electricity bill down to appliance level. In addition, gamification features, such as P2P comparisons, energy predictions and carbon footprint keep your consumers continually satisfied.',
      "How does it work? Voltaware’s cost-effective, compact sensor connects discreetly and seamlessly to any home fuse box where it works day and night, sampling high-resolution data from electrical appliances.  Voltaware has built a proprietary machine learning algorithm that automatically recognizes each appliance's electrical signature. This innovation also allows Voltaware to disaggregate smart meter statistics by receiving high resolution data through a CAD (Consumer Access Device) without the need of added sensors. In addition, API open integration and the possibility to white label makes it the only viable solution for utilities, from both an economical and technical point of view, to scale to a large consumer base",
      'Onda Energia in Sicily, Italy is a perfect example. It recently rolled out 2,000 sensors to its customers to engage them. Here is what Onda CEO Luigi Martines is saying: "Our customers do not understand their energy bill, Voltaware changes that. Our consumers reacted very positively to Onda after using the Voltaware powered product." As a result, Onda Energia has now extended its order to 10,000 sensors for Sicily and Sardinia.',
      'Consumers have choices, engaging them means creating better business strategies through relevant customer insight and the opportunity for utilities to shift from commodity to services. Simple to install and economical, utilities can white-label the Voltaware app or integrate its data into their own services. Voltaware’s technology is a win-win value proposition for provider and user.  So, is your utility ready to innovate and implement a strategy that involves something new for customers? Contact us today for a demonstration of what we can do for you.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-9.jpg',
    date: '16 Jan 2020',
    link: '/blog/2020-01-16/a-smarter-future',
    title: 'A smarter future',
    preview:
      'Voltaware’s home intelligence is making our homes smarter and safer – but this is just the beginning',
    text: [
      'We’ve been hearing about the ‘smart home revolution’ for a number of years now, but has it lived up to the hype? Sure, home-owners can now adjust their heating, switch on their lights or operate their vacuum cleaner with just a few taps of their phone’s touchscreen. But while all of those things are undoubtedly novel, does anything exist in the smart sphere that makes a significant contribution to people’s lives?',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-9.jpg" alt="A smarter future" /></div>',
      'Well, yes actually. Voltaware has developed an advanced home intelligence system that is designed to make homes truly safe, secure, reliable and efficient. By automatically collecting high-resolution data from a home’s electrical appliances, it is able to relate vital information to the home-owner via their smartphone; information that could reduce their monthly utility bills, minimise their carbon footprint, and even save their family’s lives.',
      'Voltaware’s home intelligence will provide round-the-clock protection to your home and its occupants. Not only can it calculate if your electrical appliances are faulty and in danger of catching fire, enabling you to replace them or have them repaired. It can also detect – by learning your daily habits – if any unusual activity is taking place in your home. For example, if you’re away on holiday and the lights in your home are switched on, Voltaware’s intelligence system will assume that something is wrong and immediately alert you to the danger.',
      'This concept works for elderly or disabled monitoring, too. If you live remotely from a vulnerable relative and are concerned about their wellbeing, you can install Voltaware’s home intelligence in their house or flat, and it will immediately notify your smartphone if there are any changes to your loved one’s routine – for example, if they don’t use the kettle for the first time in a few days. This will give you the opportunity to check up on them to make sure everything is OK.',
      'Although Voltaware has been instrumental in steering the smart-home revolution in a more impactful direction, we have not even come close to unleashing the full potential of our technology. Our data scientists are currently working alongside our esteemed partners to take the next step in this exciting journey. Maybe in the not too distant future, Voltaware will be able to provide home-owners with advanced intelligence on all of their home’s utilities – their electricity, gas and water. Not only would this further enhance their home security, through notifications of gas leaks, floods, etc, it would also provide them with even greater transparency with regard to their monthly spend.',
      'The goal is firmly in sight. And with Voltaware already having proved itself as a technological innovator, there is no one better to lead the charge.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-8.jpg',
    date: '20 Dec 2019',
    link: '/blog/2019-12-20/power-to-your-wallet',
    title: 'Power to your wallet',
    preview:
      'Voltaware’s top tips for saving money through better energy management',
    text: [
      'With the cost of living on the rise, many people in the UK are struggling to make ends meet. Happily, with a few simple lifestyle tweaks – and the help of Voltaware’s advanced energy intelligence – you can reduce your outgoings substantially...',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-8.jpg" alt="Power to your wallet" /></div>',
      '<h3>Switch your appliances off when you’re not using them</h3> We’re all guilty of leaving the bathroom light on when we go downstairs, or putting the TV on standby instead of switching it off when we go to bed. But while these things may seem insignificant at the time, they can really impact on your monthly electric bill. Voltaware will help you to change your habits. Via its user-friendly app, it will deliver a clear and comprehensive breakdown of your home’s energy consumption, enabling you to identify exactly how much electricity each appliance is using, and how much it is costing you. You will also receive recommendations on how you can lower your consumption (and reduce your carbon footprint), as well as motivational monthly reports to keep your energy-saving campaign on track.',
      '<h3>Pre-empt when your appliances might break down</h3> When you’re saving for a holiday or trying to put some money away for your children’s education, the last thing you want is to have to splash out on a new refrigerator or washing machine. Fortunately, with Voltaware, such eventualities are easily avoidable. By monitoring the energy consumption of your home’s appliances 24/7, Voltaware can determine if something is not performing at its best. In the event that something is amiss, it will immediately send a notification to your smartphone, giving you time to fix the problem – of get it fixed by a professional – before the appliance completely breaks down.',
      '<h3>Keep an eye on your home while you’re away</h3> Going on holiday leaves your home vulnerable to fire and burglary, both of which can have huge financial implications. Many people install state-of-the-art alarm systems to protect against such eventualities, but these can be expensive in themselves. That’s where Voltaware proves invaluable: by monitoring your home’s appliances round the clock, it can detect if you have left something on, causing a potential fire hazard; or if unusual activity is occurring in your house, which may point to criminal activity. It will then immediately notify your smartphone, wherever you happen to be, so that you can nip the hazard in the bud before it’s too late.',
      '<h3>Have a better relationship with your home</h3> Considering how much time the average person spends at home, we should all be experts on our living space. And yet, many of us remain ignorant to what’s going on around us: how much energy our home consumes, how much it is costing us – not to mention the subsequent impact on the environment. Voltaware will bridge that gap between you and your home: through its user-friendly smartphone app, it will provide you with a clear disaggregation of your monthly bills, meaning that you won’t get any unwanted surprises when that envelope drops on your doormat. The app will also offer monthly energy-saving tips and P2P comparisons, helping you to keep your monthly spend to the absolute minimum.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-7-cover.jpg',
    date: '26 Nov 2019',
    link: '/blog/2019-11-26/the-relative-benefits-of-voltawares-elderly-monitoring',
    title: 'The relative benefits of Voltaware’s elderly monitoring',
    preview:
      'Worried about an elderly or disabled relative? Voltaware’s home-intelligence solution uses energy-data analysis to monitor their activity, giving you peace of mind 24/7',
    text: [
      'The United Kingdom is getting older. According to a study published by the Office for National Statistics, 18.2% of people living in the UK in 2017 were aged 65 or over – up from 15.9% in 2007. And the number of elderly people in the country is set to increase further in the coming decade – by 2027, it is predicted that over-65s will make up 20.7% of the overall population.',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-7.jpg" alt="How Voltaware is energising the planet" /></div>',
      'While many people continue to live healthy, active lives well into their retirement, others are much more vulnerable, especially if they live alone – and that can be a major concern for their nearest and dearest. Of course, in an ideal world we would all be there to manage our relatives’ needs 24/7, but with other commitments that is not always possible. So how can we gain peace of mind – both for ourselves and for them?',
      'While many people continue to live healthy, active lives well into their retirement, others are much more vulnerable, especially if they live alone – and that can be a major concern for their nearest and dearest. Of course, in an ideal world we would all be there to manage our relatives’ needs 24/7, but with other commitments that is not always possible. So how can we gain peace of mind – both for ourselves and for them?',
      'Once Voltaware’s credit-card-sized sensor is hooked up to a home’s electricity box via patented magnetic connectors, it uses a state-of-the-art machine learning algorithm to identify appliances, then begins to sample high-resolution energy data from each one. This data is sent via Wi-Fi to Voltaware’s secure cloud, where it is analysed before the results are delivered to a smartphone app.',
      'With Voltaware installed in your elderly or disabled relative’s home, but paired to your own smart device, you can remotely monitor their daily behaviours. The app will provide an easily digestible breakdown of their electricity usage down to appliance level, enabling you to identify if they have being using their cooker, switching on their electric fire or watching the television. And with no camera or listening devices required, this non-intrusive process will not impact on their privacy.',
      'If you are too busy to check the app at regular intervals, that is not a problem as Voltaware will automatically learn your relative’s behaviours and notify you if something seems wrong. For example, if they have not used the kettle for the past 24 hours, or watched TV all evening, it will immediately send an alert to your smartphone, so that you can check up on them.',
      'Of course, with this level of insight at your fingertips, you can also help your relative to manage their electricity use more efficiently, saving  money on their utility bills. The Voltaware app will provide a detailed readout of how much energy each appliance is consuming, and how much money it is costing, enabling you – and your relative – to make more informed energy-preserving choices.',
      'Having an elderly or disabled relative who lives alone can be a genuine worry, but with Voltaware providing accurate, non-intrusive monitoring around the clock, you can keep an eye on their safety and wellbeing, even when you are unable to be there in person.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
        title: 'How Voltaware is energising the planet',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-6-cover.jpg',
    date: '26 Nov 2019',
    link: '/blog/2019-11-26/how-voltaware-is-energising-the-planet',
    title: 'How Voltaware is energising the planet',
    preview:
      'Voltaware’s market-leading home intelligence solution can help us all to become more energy-efficient, giving the planet a much-needed boost',
    text: [
      'Our planet is at breaking point – and here in Europe, we are very much a part of the problem. While China (27% of global CO2 emissions) and the US (15%) have the biggest carbon footprints, the EU isn’t far behind, being responsible for 9% of the world’s carbon dioxide.',
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-6.jpg" alt="How Voltaware is energising the planet" /></div>',
      'What makes that statistic worse is the fact that the EU accounts for just 6.7% of the world’s population, meaning that each European produces 40% more carbon than the world average.',
      'The emergency is certainly not being ignored. As part of its ‘Europe 2020’ strategy outlined in 2010, the European Commission pledged to reduce its greenhouse gas (GHG) emissions by at least 20% compared to 1990 levels. And it is more than making good on that promise, with the EU’s GHG levels dropping by 21.7% in 2017.',
      'However, there is clearly still a huge amount of work to be done. Of course, we all look forward to a future where fossil fuels have been universally replaced by alternative sources – solar, wind, water – that deliver clean and sustainable energy. But it’s not a simple fix. Not only is there a significant financial outlay attached to renewable energy sources, but doubts have also been cast about their effectiveness.',
      'Until such obstacles are overcome, we can all ‘do our bit’ for the environment by better managing the amount of energy we use in our homes – and this has never been easier, thanks to Voltaware’s market-leading home intelligence solution.',
      'Taking just five minutes to install (a non-intrusive, credit-card-sized sensor hooks up to an electricity meter via patented magnetic connectors), Voltaware uses a state-of-the-art machine-learning algorithm to sample high-resolution data from a home’s appliances. This is then sent to a secure cloud for analysis, before the results are delivered back to home-owners via a user-friendly smartphone app.',
      'With a detailed breakdown of their home’s electricity consumption at their fingertips, users will gain a much clearer and more accessible insight. They will be able to learn which appliances consume the most electricity and how much it is costing them per month, which in turn will help them to become more energy-efficient. Not only will this benefit the planet, it will help home-owners to reduce their monthly utility bill.',
      'And Voltaware offers numerous other benefits, too. By monitoring your home’s appliances 24/7, it can detect if your kettle, microwave or fridge is not performing efficiently, and send you a notification so that you can arrange for it to be repaired before it’s too late.',
      'Got an elderly or disabled relative that you’re concerned about? Through non-intrusive monitoring of their daily energy usage, Voltaware will learn their behaviours and notify you if something seems wrong – for example, if their kettle hasn’t been used all day – so that you can check up on them.',
      'With so many benefits, both to the planet and to home-owners, investing in Voltaware is an easy decision. Now is the time to make your home energy consumption leaner, greener – and cheaper.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/the-relative-benefits-of-voltawares-elderly-monitoring',
        title: 'The relative benefits of Voltaware’s elderly monitoring',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-img-5-cover.JPG',
    date: '25 Nov 2019',
    link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
    title: "Extending Voltaware's partnership with Sinergia Group",
    preview:
      'Voltaware’s partnership with Sinergia Group proves the benefits of home intelligence in Italy',
    text: [
      `<ul>
        <li>Sinergia Group is increasing Voltaware’s technology rollout.</li>
        <li>The technology will extend to 10,000 Sinergia’s customers.</li>
        <li>The sensor and app enable customers to manage their energy consumption and reduce their bills.</li>
      </ul>`,
      '<div style="display: flex; justify-content: center"><img src="/img/del/blog-img-5.jpg" alt="Luigi Martines, Sinergia Group CEO & Sergey Ogorodnov, Voltaware’s CEO" /></div>',
      'Sinergia Group is increasing Voltaware technology rollout for its customer to truly become a home energy innovator in Italy. The sensor and app enable customers to manage their energy consumption and reduce their bills. The company has already been providing the technology to its 2,000 customers and is now extending it to 10,000 customers across Sicily and Sardinia. The extension of this partnership was signed last week in Paris at the European Utility Week.',
      'Voltaware’s sensor easily connects to the fuse box and sends high-resolution data from each connected appliance via Wi-Fi to the app enabling cloud-based analysis. Customers receive a detailed breakdown of their energy usage and cost whilst making their homes safer, greener, and more efficient.',
      '<b>Luigi Martines</b>, Sinergia Group, <b>CEO</b>, says: “Our customers are very happy with the new application backed by Voltaware technology. They can now understand their electricity bills and manage their energy consumption better. We are delighted to extend this technology, which helped us to acquire customers cheaper and retain them longer.',
      '<b>Sergey Ogorodnov</b>, <b>Voltaware’s CEO</b>, says: “We are thrilled to extend the partnership with Sinergia Group to deploy our technology across Sicily. This new rollout will assist in learning how to improve the algorithms further and to increase the benefits of home intelligence - making homes much more energy-efficient.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/the-relative-benefits-of-voltawares-elderly-monitoring',
        title: 'The relative benefits of Voltaware’s elderly monitoring',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    cover: '/img/del/blog-cover-understand-your-monthly-report.png',
    date: '14 Jun 2018',
    img: '/img/del/blog-img-understand-your-monthly-report.png',
    link: '/blog/2018-06-14/understand-your-monthly-report',
    title: 'Understand your Monthly Report',
    preview:
      'Since May 2018, Voltaware has been delivering a monthly appliance-level breakdown detailing the electricity cost by individual appliance or appliance family...',
    text: [
      '<b>The appliance categories</b>',
      'Since May 2018, Voltaware has been delivering a monthly appliance-level breakdown detailing the electricity cost by individual appliance or appliance family:',
      `<ul>
                <li><b>Standby</b> (these are the appliances that run all day long in standby mode),</li>
                <li><b>Fridge & Freezer</b>,</li>
                <li><b>Lighting & Entertainment</b> (lights, computers, TVs, etc.),</li>
                <li><b>Washing</b>,</li>
                <li><b>Heating & Cooking</b> (the most common appliances to fall in this category are electric radiator, electric cooker, electric oven, electric boiler. In fact, this category contains the purely resistive appliances of the house),</li>
                <li><b>Others</b> (usually contains microwave, tumble dryer, hair dryer, air conditioner, vacuum cleaner).</li>
            </ul>`,
      'For users who have completed the questionnaire as part of their monthly report, we will be able to provide a refined breakdown including appliances such as:',
      `<ul>
                <li><b>Washing Machine</b></li>
                <li><b>Dishwasher</b></li>
                <li><b>Electric Shower</b></li>
                <li><b>Electric Boiler</b></li>
                <li><b>Tumble Dryer</b></li>
            </ul>`,
      '<b>House conditions</b>',
      `<ul>
                <li><b>Always on</b> describes the condition the house is in for those appliances that run 24 hours a day. In most cases these appliances are those in standby mode and the fridge or freezer.</li>
                <li><b>Normal</b> details the condition the house is in when some appliances with moderate consumption are switched on.</li>
                <li><b>Busy</b> defines the condition the house is in when the appliances with the highest power draw are switched on.</li>
            </ul>`,
      '<b>How does it work?</b>',
      `Voltaware proposes a single sensor solution to increase transparency on electricity bills. The Voltaware sensor is placed in the fuse box and sends electrical data to the cloud in real time.<br/>
            In a household, each electrical appliance has a unique fingerprint that is collected by the sensor. At regular intervals, Voltaware’s machine learning algorithms use these fingerprints to break down the electricity consumption into categories of appliances.<br/>
            Voltaware’s mobile app is connected to hundreds of electricity providers’ tariffs in the world. This is how the consumption per category is accurately converted into the cost per category.`,
      '<b>Precision</b>',
      `To evaluate the performance of the machine learning algorithms, Voltaware has built a testing environment consisting of several houses around the world in which the consumption of key appliances is recorded.<br/>
            The issue of breaking down the electricity consumption is very hard to solve as each house has different appliances and each appliance fingerprint can vary from one model to another. Therefore, the performance of the algorithms declines as the number of appliances in the house increases. The use of some particular appliances also worsens the performance of the algorithms.<br/>
            Results obtained from our testing environment show that the appliance breakdown has a +/- 1% precision rate in more than 90% of households with up to four people.<br/>
            The following factors can degrade the performance of the algorithm:<br/>
            <ul>
                <li>Multiple fridges or multiple washing machines in the same house</li>
                <li>More than 4 electrical radiators</li>
                <li>Big computers or servers</li>
            </ul>`,
      '<b>Next improvements</b>',
      `Voltaware’s data science team applies cutting edge techniques to solve the complex problem of bringing transparency to electricity bills.<br/>
            Voltaware’s testing environment is growing constantly, and the data science team discovers more and more improvements for the existing appliance models and for the appliance category breakdown. These improvements are used every month to update the machine learning algorithms and to improve the precision of the report delivered to Voltaware’s users.`,
      'Please don’t forget to fill out the attached form linked to your monthly report so we can keep on improving our results and provide you with the best possible product.',
    ],
    tags: [],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/the-relative-benefits-of-voltawares-elderly-monitoring',
        title: 'The relative benefits of Voltaware’s elderly monitoring',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
  {
    author: 'Voltaware',
    date: '9 Feb 2017',
    img: '/img/del/blog-img-1.jpg',
    link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
    title: 'You deserve control over your spend on energy',
    preview:
      'UK households already face some of the highest energy prices in Europe, so the recent price hike announcements from some of the ‘Big Six’ energy suppliers will be cause for concern for millions...',
    text: [
      'UK households already face some of the highest energy prices in Europe, so the recent price hike announcements from some of the ‘Big Six’ energy suppliers will be cause for concern for millions of customers.',
      'In spite of warnings from Ofgem against the Big Six increasing their prices, coinciding in some cases with the end of a winter price freeze on tariffs, a number of tariffs look set to rise this spring and this has been met with condemnation from the government and Ofgem alike.',
      'Already hitting the headlines is a 9.8% increase in energy prices for duel fuel customers by Npower, adding an average of £109 to customer bills. Furthermore, Npower’s standard tariff customers should expect a 15% increase in electricity prices and a 4.8% increase in gas prices from 16th March.',
      'EDF energy have announced an 8.4% increase in electricity prices, while it was revealed this week that British Gas is expected to increase prices by 9% which could see bills increase by nearly £100 annually.',
      'As a customer we cannot control what we are charged per kWh beyond switching tariffs (which we recommend is a simple and effective step in reducing energy costs). We can however control how many kWh’s we use, but the trouble is, few of us understand how many kWh’s we use on average – per day/month/year, let alone when and where we are using the most energy.',
      'Voltaware was founded on this exact concept; giving back control to the customer through enabling understanding of kWh consumption at an appliance level, and indicating in real-time as to when electricity is (or isn’t) being used. Conscious energy-behaviour change has the fantastic benefit of more efficient use of energy too.',
      'Providing customers with the tools, and the awareness of services which can help them protect themselves from soaring energy prices is important. Energy suppliers are far from unfamiliar with the concept of ‘Smart homes’ and home energy management devices and systems, for example, supplier-promoted smart thermostats are becoming more and more common place. However, such services in electricity are yet to reach a larger audience.',
      'Right now the governments answer is smart meters, but with huge associated costs (relayed to the customer), delays in roll-outs and limited perceived functionality and insight for the end user on their energy use, it begs the question whether they’re actually going to help customers make any significant savings on their energy bills. As affordability and accessibility barriers are broken down, we see smarter and more innovative services in electricity monitoring making a more substantial difference to customers’ ability to reduce their energy spend on energy.',
    ],
    tags: [
      {
        name: 'Energy bills',
        link: '#',
      },
      {
        name: 'Bix Six',
        link: '#',
      },
      {
        name: 'Energy consumption',
        link: '#',
      },
      {
        name: 'Smart meter',
        link: '#',
      },
      {
        name: 'Energy saving',
        link: '#',
      },
      {
        name: 'Energy monitor',
        link: '#',
      },
    ],
    related: [
      {
        img: '/img/del/blog-img-1.jpg',
        link: '/blog/2017-02-09/you-deserve-control-over-your-spend-on-energy',
        title: 'You deserve control over your spend on energy',
      },
      {
        img: '/img/del/blog-img-7-cover.jpg',
        link: '/blog/2019-11-26/the-relative-benefits-of-voltawares-elderly-monitoring',
        title: 'The relative benefits of Voltaware’s elderly monitoring',
      },
      {
        img: '/img/del/blog-img-5-cover.JPG',
        link: '/blog/2019-11-25/extending-voltawares-partnership-with-sinergia-group',
        title: "Extending Voltaware's partnership with Sinergia Group",
      },
    ],
  },
];

const getBlogPost = (link) => BlogPosts.find((post) => post.link === link);

export { BlogPosts, getBlogPost };
