export default [
  {
    name: 'Sergey Ogorodnov',
    role: 'Chairman & Founder',
    about:
      'A co-founder of Voltaware and responsible for business development. Sergey has 20+ years working experience with focus on telecoms and was CEO of the largest provider of fibre optics and mobile connectivity solutions to major Russian operators, but has always had an entrepreneurial spirit. His hobbies include tennis, running, and chess.',
    img: {
      src: '/img/team/members/sergey.jpg',
      alt: 'sergey ogorodnov',
    },
  },
  {
    name: 'Rishabh Jaipuria',
    role: 'Chief Operating Officer',
    about:
      'Rishabh is passionate about everything mobile and digital. With over 15 years in the industry, he is now leading the product vision and marketing at Voltaware. He’s always got his eye on industry trends, making sure we continue to stay way ahead of the curve. When not working, he can be seen cooking and spending time with his two kids.',
    img: {
      src: '/img/team/members/rishabh.jpg',
      alt: 'rishabh jaipuria',
    },
  },
  {
    name: 'Gonçalo Moraes',
    role: 'Head of Development',
    about:
      'Gonçalo is responsible for our technology, specifically leading our app development. With over 20 years in technology startups he has plenty of experience to bring to the table.',
    img: {
      src: '/img/team/members/goncalo.jpg',
      alt: 'gonçalo moraes',
    },
  },
  {
    name: 'Dr. Antoine Clais',
    role: 'Head of Data Science',
    about:
      'Antoine holds a Ph.D in Mathematics and has 2 years of experience as a post-doctoral researcher. As a Data Scientist, he enjoys finding mathematical patterns in data. Outside of work he loves to read Marcel Proust and cook quiche Lorraine.',
    img: {
      src: '/img/team/members/antoine.jpg',
      alt: 'dr antoine clais',
    },
  },
  {
    name: 'Dr. Furqan Tahir',
    role: 'Head of Smart Meter Insights',
    about:
      'Furqan holds a PhD degree in Electrical Engineering from Imperial College London and is a Chartered Engineer (CEng) with 10+ years of experience working in the UK industry. As the Head of Smart Meter Insights, he leads a team of Data Scientists, Data Analysts and Backend Engineers to develop AI/ML based solutions for energy disaggregation and personalised actionable insights using only the home’s smart meter consumption data. In his spare time, Furqan enjoys hiking, running and playing tennis.',
    img: {
      src: '/img/team/members/furqan.jpg',
      alt: 'furqan',
    },
    style: {
      objectPosition: 'center 25%',
    },
  },
  {
    name: 'Elena Blair',
    role: 'Head of HR',
    about:
      'Elena holds an MCIPD qualification and has over 19 years of experience within blue chip multinational organisations. Committed to good practice in HR, she strives to provide pragmatic and balanced advice and has great awareness of Corporate Social Responsibility. She enjoys trips to the gym and taking her dog for walks.',
    img: {
      src: '/img/team/members/elena.jpg',
      alt: 'elena blair',
    },
  },
  {
    name: 'François Gruber-Magitot',
    role: 'Head of Partnerships',
    about:
      'After having finished his Master of European politics at the London School of Economics, François decided to shift towards the energy sector. At Voltaware, he is responsible for Sales, Marketing and Business Development. He has lived in France, Germany, Spain and the UK, and is fluent in those four languages. Outside work, François loves Muay Thai, mountaineering and music.',
    img: {
      src: '/img/team/members/francois.jpg',
      alt: 'françois gruber-magitot',
    },
  },
  {
    name: 'Aurore Prefumo',
    role: 'Office Manager/Finance Officer',
    about:
      'Aurore is the Company’s friendly Office Manager and in charge of its Finances & Accounting. She is about to get her ACCA qualification. She is highly ethical in her role, ensuring that everyone gets paid on time and enjoys preparing management accounts. She enjoyed Maths at school and holds an LLB and even worked as Paralegal. Aurore is bi-lingual in English and French. Aurore has many passions including dancing, playing badminton & walking. In her little spare time she enjoys spiritual development and human design. She can even read astrological charts :). She also loves playing board games with her family.',
    img: {
      src: '/img/team/members/aurore.png',
      alt: 'aurore prefumo',
    },
    style: {
      objectPosition: 'center 75%',
    },
  },
  {
    name: 'Gian Paolo Alessi',
    role: 'Product Manager',
    about:
      'Gian develops design tools to help the company manage itself more efficiently and does QC on our products and features. He has a double major in Industrial and Computer Engineering. He is Mexican, French and Italian, and speaks four languages fluently. he loves climbing, hiking and photography, but he always has time to make Mexican breakfast.',
    img: {
      src: '/img/team/members/gian.jpg',
      alt: 'gian paolo alessi',
      link: 'https://www.linkedin.com/in/gian-paolo-alessi-a401b5114/',
    },
  },
  {
    name: 'Dinesh Kumar Deenadayalan',
    role: 'Product Executive',
    about:
      "Not much escapes Dinesh's keen eye for detail, if something's not right, he'll find it! With 3 years’ experience as a product manager he plays a central role in developing our app. In his spare time he loves to read and keep up to date with current world affairs.",
    img: {
      src: '/img/team/members/dinesh.jpg',
      alt: 'dinesh kumar deenadayalan',
    },
  },
  {
    name: 'Emil Aliev',
    role: 'Business Development Executive',
    about:
      "Emil studied BSc Economics and Management at King's College London and holds an MSc in Finance from Bayes Business School. After completing his master’s degree, he started his career as a Business Development Executive at Voltaware. He is responsible for managing sales, building partnerships, and handling client relations. Fluent in four languages, Emil brings a global perspective to their role. Outside of work, he enjoys reading, going to the gym, and discovering new music.",
    img: {
      src: '/img/team/members/emil.png',
      alt: 'emil aliev',
    },
  },
  {
    name: 'Antonio Lago',
    role: 'Senior Software Engineer',
    about:
      'With 6 years of experience behind him, Antonio has a passion for coding and is a member of our app development team. When he is not coding he likes to utilise his software writing skills. He particularly enjoys reading about philosophy and understanding the world we live in.',
    img: {
      src: '/img/team/members/lake.jpg',
      alt: 'antonio lake',
    },
  },
  {
    name: 'William Lopes',
    role: 'Machine Learning Engineer',
    about:
      'With a Masters degree in Applied Computing and 10 years of experience in programming, notably in developing Computer Vision systems, William now plays an important role in advancing our machine learning algorithms. When not at work he loves to travel and read books about ancient history.',
    img: {
      src: '/img/team/members/william.jpg',
      alt: 'william lopes',
    },
  },
  {
    name: 'Rodrigo Lucchesi',
    role: 'Machine Learning Engineer',
    about:
      'Rodrigo is an Energy/Software Engineer interested in applying machine learning in energy systems, environment issues and the human mind. He is also an Open Source user and an enthusiast big wave surfer and martial arts practitioner.',
    img: { src: '/img/team/members/rodrigo.jpg', alt: 'rodrigo' },
    style: {
      objectPosition: 'center 28%',
    },
  },
  {
    name: 'Guzel Oktyabreva da Silva',
    role: 'Business Development Representative',
    about:
      'Guzel is a seasoned sales professional with 19+ years of experience in generating leads and identifying new business opportunities. With a background in Linguistics and an MSc in Tourism, Leisure, and Environment, she brings a unique perspective to her work. Guzel is passionate about sales, people, and communication, and her linguistic skills—fluent in English, Russian, Portuguese, and Spanish—enhance her ability to connect with diverse clients and stakeholders. In her free time, she enjoys doing sports, going to the beach, and chatting with her innumerable friends.',
    img: {
      src: '/img/team/members/guzel.jpg',
      alt: 'guzel',
    },
    style: {
      objectPosition: 'center 75%',
    },
  },
  {
    name: 'Michael Dessi',
    role: 'Customer Support Advisor',
    about:
      'Michael is a dedicated Customer Support Advisor specializing in the Italian market. His diverse professional background has equipped him with a strong foundation, which he continues to build upon through ongoing learning. Michael is committed to delivering exceptional service and ensuring customer satisfaction.',
    img: {
      src: '/img/team/members/michael.jpg',
      alt: 'michael',
    },
    style: {
      objectPosition: 'center 20%',
    },
  },
  {
    name: 'Giovanni Gasparini',
    role: 'Data Scientist',
    about:
      'Giovanni has a background in physics and Industry experience in research and development of bio-metrics Machine Learning models. As a Data Scientist at Voltaware, Giovanni is responsible for improving the energy disaggregation algorithm within the High-resolution project. In his free time he enjoys trekking and board gaming.',
    img: {
      src: '/img/team/members/giovanni.png',
      alt: 'giovanni',
    },
  },
  {
    name: 'Loan Gauchat',
    role: 'Data Scientist',
    about:
      'Loan is a computer science graduate from Vrije Universiteit Amsterdam. He has developed his skills through his experience in software development and data analysis. At Voltware, as a Data scientist, Loan is involved in the development of data science projects for the insights. In his personal life, Loan is a fan of cooking, pottery, and staying active through walking and the occasional swim.',
    img: {
      src: '/img/team/members/loan.png',
      alt: 'loan',
    },
    style: {
      objectPosition: 'center 31%',
    },
  },
  {
    name: 'Luca Spolladore',
    role: 'Data Scientist',
    about:
      'Luca is an Energy and Nuclear Engineer with a PhD in Industrial Engineering from the University of Rome Tor Vergata. During his PhD, he honed his skills in data analysis and Machine Learning (ML), working on various projects related to Nuclear Fusion. At Voltaware, Luca applies his expertise as a data scientist, developing AI/ML-based solutions for energy disaggregation and insights using Smart Meter data. In his free time, he enjoys reading, playing guitar, and staying active through various sports.',
    img: {
      src: '/img/team/members/luca.jpg',
      alt: 'luca',
    },
  },
  {
    name: 'Maria Dancianu',
    role: 'Junior Data Scientist',
    about:
      'With a background in Economics and a recent MSc degree in Data Analytics, Maria joined the Voltaware team where she combined her interest for technology and data analysis with her passion for environmental conservation, contributing to make people more aware of their energy consumption. She loves spending her free time trekking, doing some yoga and reading.',
    img: { src: '/img/team/members/maria.jpg', alt: 'maria' },
  },
  {
    name: 'Deepika Navani',
    role: 'Data Analyst',
    about:
      'Deepika is a Computer Science Engineer with M.Tech. in the area of Natural Language Processing. She has an experience of 5 years in Academics as an Assistant Professor and over 2 years as a Data Analyst in the IT industry with Voltaware. Her responsibilities involve labeling of appliances and analyzing data, results and writing scripts. She enjoys using her skills to enhance an energy efficient way of life.',
    img: {
      src: '/img/team/members/deepika.png',
      alt: 'deepika',
    },
    style: {
      objectPosition: 'center top',
    },
  },
  {
    name: 'Hafsa Mehmood',
    role: 'Data Analyst',
    about:
      'Hafsa is a Software Engineer with a Master’s in Artificial Intelligence. With over a year of experience as a Data Analyst at Voltaware, Hafsa specializes in time series data analysis, other than this, her responsibilities include appliance labeling and disaggregation results analysis . In her free time, she enjoys watching movies and playing games with family.',
    img: {
      src: '/img/team/members/mehmood.jpg',
      alt: 'mehmood',
    },
    style: {
      objectPosition: 'center 5%',
    },
  },
  {
    name: 'Ivan Balhawan',
    role: 'Data Analyst',
    about:
      'Ivan is an Electrical and Computer Engineering graduate from the American University of Beirut. With over 4 years of experience in the data science and software industry, he leverages his analysis skills to identify potential improvements within the High-Resolution Disaggregation project. In his free time, he enjoys playing video games and cycling.',
    img: {
      src: '/img/team/members/ivan.jpg',
      alt: 'ivan',
    },
  },
  {
    name: 'Muhammad Ahmad Khan',
    role: 'Data Analyst',
    about:
      'Ahmad holds an MS degree in Artificial Intelligence, specializing in Explainable Artificial Intelligence (XAI). He has 2 years of experience as a Research and Teaching Assistant in academia, as well as over a year of experience as a Data Analyst in the IT industry with Voltaware. His responsibilities include appliance labeling, smart meter data analysis, and energy disaggregation results analysis. Besides his professional work, he enjoys traveling and reading books.',
    img: {
      src: '/img/team/members/ahmad.png',
      alt: 'ahmad',
    },
    style: {
      objectPosition: 'center top',
    },
  },
  {
    name: 'Pedro Pacheco',
    role: 'DevOps Engineer',
    about:
      'Working professional with more than 12 years of IT experience, Pedro is active in DevOps practices. Currently working as Cloud Infrastructure Engineer at Voltaware, Pedro is helping the company have the most security and reliable system. He loves to read and talk about Social Science and practice Yoga.',
    img: { src: '/img/team/members/pacheco.png', alt: 'pacheco' },
    style: {
      objectPosition: 'center 10%',
    },
  },
  {
    name: 'Tiago Perini',
    role: 'DevOps Engineer',
    about:
      'Tiago has more than 10 years of experience as a sysadmin, managing especially Linux systems, and more than 5 years of experience with virtualized environments management. He holds a technology degree in computer networks and, at Voltaware, plays the role of cloud infrastructure engineer. He dedicates his free time to music or following scientific dissemination content in general.',
    img: { src: '/img/team/members/perini.png', alt: 'perini' },
  },
  {
    name: 'Dr. Daniela Uez',
    role: 'Quality Assurance Specialist',
    about:
      "Daniela has a BSc in Computer Science from University of Caxias do Sul, an MSc and a PhD in Automation and Systems Engineering from the Federal University of Santa Catarina working on agent oriented software engineering and multiagent systems. She has been working in software development since 2003. She loves learning new things so she reads a lot; fantasy and history, and she's passionate about movies.",
    img: { src: '/img/team/members/daniela.jpg', alt: 'daniela' },
  },
  {
    name: 'Pedro Ramalho',
    role: 'Front-end Developer',
    about:
      'After pursuing a background in Civil Engineering, Pedro has fully embraced the Software Development world. He loves to learn new things every day and being challenged. He mostly works with Front-end technologies. Believes the world is constantly changing and that we must adapt to it.',
    img: { src: '/img/team/members/ramalho.jpg', alt: 'ramalho' },
  },
  {
    name: 'Tatiana Pirondi',
    role: 'Web Designer',
    about:
      'Tati is a Web Designer over 10 years. She’s passionate about Design and had worked from social networks, site layouts, brand development to UI design. On her free time, she likes to read a lot and learn new things, music and books are her passions.',
    img: { src: '/img/team/members/tati.jpg', alt: 'tati' },
  },
  {
    name: 'Agustina Bergmann',
    role: 'UX/UI Designer',
    about:
      'Agustina is a passionate UX/UI Designer, who artfully blends creativity, communication and technology to craft digital experiences that resonate deeply with users. Her design philosophy is rooted in the belief that every interface is a canvas, and every interaction is a narrative. Guided by empathy, she ensures that each element serves both aesthetic and functional purposes. As an instructor in human-centered design methodologies, Agustina inspires others to view design not just as a craft, but as a means to connect, empathize, and harmonize beauty with functionality.',
    img: {
      src: '/img/team/members/agustina.png',
      alt: 'agustina',
    },
    style: {
      objectPosition: 'right center',
    },
  },
  {
    name: 'Gabriel Baggio',
    role: 'Scrum Master',
    about:
      'With a Bachelor Degree in Industrial Engineering, Gabriel is passionate about Agile methodology and Management, he has wide experience in both project and people management. His strive for personal development makes him believe that process improvements can be applied almost everywhere.',
    img: { src: '/img/team/members/gabriel.jpg', alt: 'gabriel' },
    style: {
      objectPosition: 'center top',
    },
  },
];
